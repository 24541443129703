<template>
  <div class="p-6">
    <base-page-title :title="pageTitle" :suffix="pageSuffix" :icon="meta.titleIcon"></base-page-title>

    <div class="flex flex-row">
      <span class="basis-1/2">
        <base-card class="text-gray-400" :loading="useViewClusterDetails.loadHandler.states.get_details">
          <!-- edit button -->
          <base-button
            v-if="useContentGuard.canSee('object:write')"
            class="mb-2"
            @action="useViewClusterDetails.openEdit()"
            ><featherIcon class="w-4 h-4" icon="EditIcon"
          /></base-button>
          <base-list :data="useViewClusterDetails.cluster.value" :fields="useViewClusterDetails.clusterFields.value" />
        </base-card>
      </span>
      <span class="basis-1/2 ml-4">
        <base-card class="text-gray-400" :loading="useViewClusterDetails.loadHandler.states.get_details">
          <!-- map -->
          <nodeMapMultiple
            v-if="useViewClusterDetails.cluster.value?.nodes?.length > 0"
            :markerType="useViewClusterDetails.cluster.value.medium"
            :nodes="useViewClusterDetails.cluster.value.nodes"
          />
        </base-card>
      </span>
    </div>

    <base-page-title title="NodesClusters" class="mt-2"></base-page-title>

    <!-- nodes in cluster -->
    <baseTable
      :fields="useViewClusterDetails.nodeFields.value"
      :records="useViewClusterDetails.cluster.value.nodes"
      :records-per-page="25"
      :add-button="useContentGuard.canSee('object:write')"
      :edit-button="false"
      :delete-button="useContentGuard.canSee('object:write')"
      :clickAble="true"
      :clickCallback="goToNode"
      :loading="useViewClusterDetails.loadHandler.states.get_details"
      :hover-effect="true"
      leadIcon="CircleIcon"
      :search="false"
      @add="useViewClusterDetails.openAddNodes()"
      @delete="useViewClusterDetails.unAssignNode"
      :sortable="true"
      :demo="useGlobal.settings.value.demo"
    />

    <!-- interval selector -->
    <intervalSelector class="mt-4" />

    <!-- usage -->
    <div class="flex flex-col mt-4">
      <base-card class="text-gray-400 w-full h-full mb-4">
        <div
          class="flex justify-center"
          v-if="showNoDataMessage && !useViewClusterDetails.loadHandler.somethingLoading.value"
        >
          <div>{{ $translate('No data') }}</div>
        </div>
        <div v-else class="flex justify-center">
          <baseLoader class="text-gray-500" :loading="useViewClusterDetails.loadHandler.somethingLoading.value" />
        </div>

        <cardChart
          v-if="
            !useViewClusterDetails.loadHandler.somethingLoading.value &&
            useViewClusterDetails.chart_data_usage.value.length > 0
          "
          :options="useViewClusterDetails.chart_options_usage.value"
          label="Usage"
          :exportUnit="useLookup.utilities[useViewClusterDetails.cluster.value.medium?.toLowerCase()]?.unit"
        />
        <div
          v-if="
            !useViewClusterDetails.loadHandler.somethingLoading.value &&
            !useViewClusterDetails.chart_data_usage.value.length > 0
          "
        >
          <div class="mb-2">Verbruik</div>
          <div class="text-sm">Geen data</div>
        </div>
      </base-card>

      <base-card class="text-gray-400 w-full h-full">
        <div
          class="flex justify-center"
          v-if="showNoDataMessage && !useViewClusterDetails.loadHandler.somethingLoading.value"
        >
          <div>{{ $translate('No data') }}</div>
        </div>
        <div v-else class="flex justify-center">
          <baseLoader class="text-gray-500" :loading="useViewClusterDetails.loadHandler.somethingLoading.value" />
        </div>

        <cardChart
          v-if="
            !useViewClusterDetails.loadHandler.somethingLoading.value &&
            useViewClusterDetails.chart_data_production_return.value.length > 0
          "
          :options="useViewClusterDetails.chart_options_return.value"
          label="Return"
          :exportUnit="useLookup.utilities[useViewClusterDetails.cluster.value.medium?.toLowerCase()]?.unit"
        />
        <div
          v-if="
            !useViewClusterDetails.loadHandler.somethingLoading.value &&
            !useViewClusterDetails.chart_data_usage.value.length > 0
          "
        >
          <div class="mb-2">Teruglevering</div>
          <div class="text-sm">Geen data</div>
        </div>
      </base-card>
    </div>

    <!-- edit modal -->
    <base-modal
      v-if="useViewClusterDetails.showEdit.value"
      @close="useViewClusterDetails.closeEdit()"
      :title="`${$translate('Edit')}`"
    >
      <baseForm :handler="useViewClusterDetails.editForm" />
      <template v-slot:buttons>
        <baseButton
          :disabled="!useViewClusterDetails.editForm.isValid.value"
          @action="useViewClusterDetails.edit()"
          class="mr-2"
          :loading="useViewClusterDetails.loadHandler.states.edit_cluster"
          >{{ $translate('Save') }}</baseButton
        >
      </template>
    </base-modal>

    <!-- add nodes modal -->
    <base-modal
      v-if="useViewClusterDetails.showAddNodes.value"
      @close="useViewClusterDetails.closeAddNodes()"
      :title="`${$translate('Add')}`"
      size="l"
    >
      <baseTable
        :fields="useViewClusterDetails.nodeFields.value"
        :records="useViewClusterDetails.unassigned_nodes.value"
        :records-per-page="15"
        :add-button="false"
        :edit-button="false"
        :delete-button="false"
        :selectable="true"
        :loading="useViewClusterDetails.loadHandler.states.get_unassigned_cluster"
        :hover-effect="true"
        leadIcon="CircleIcon"
        :search="true"
        @add="useViewClusterDetails.openAddNodes()"
        @selection="useViewClusterDetails.setSelectedUnassignedNodes"
        :sortable="true"
      />

      <p v-for="(value, key) in useViewClusterDetails.mixedState.value.issues" :key="key" class="text-red-500">
        <span v-if="value.status === false">
          {{ $translate(value.message) }}
        </span>
      </p>

      <template v-slot:buttons>
        <baseButton
          @action="useViewClusterDetails.assignNodes()"
          class="mr-2"
          :loading="useViewClusterDetails.loadHandler.states.assign_to_cluster"
          :disabled="
            useViewClusterDetails.unassigned_nodes_selected.value.length === 0 ||
            !useViewClusterDetails.mixedState.value.conclusion
          "
          >{{ $translate('Save') }}</baseButton
        >
      </template>
    </base-modal>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { computed, onMounted } from 'vue'
import viewHandlerClusterDetails from '@/use/viewHandlerClusterDetails'
import nodeMapMultiple from '@/views/components/nodeMapMultiple.vue'
import cardChart from '@/views/components/cardChart.vue'
import intervalSelector from '@/views/components/intervalSelector.vue'
import featherIcon from '@/components/extended/feather/featherIcon.vue'
import router from '@/router'
import contentGuardHandler from '@/use/contentGuardHandler'
import globalHandler from '@/use/handlerGlobal'
import lookupHandler from '@/use/lookupHandler'

export default {
  setup() {
    const route = useRoute()
    const meta = computed(() => route.meta)
    const useViewClusterDetails = viewHandlerClusterDetails()
    const useContentGuard = contentGuardHandler()
    const useGlobal = globalHandler()
    const useLookup = lookupHandler()

    onMounted(() => {
      useViewClusterDetails.getDetails({
        object_id: route.params.objectId,
      })
    })

    const pageTitle = computed(() => {
      return meta.value.title
    })

    const pageSuffix = computed(() => {
      if (!useViewClusterDetails.loadHandler.states.get_details) {
        return `${useViewClusterDetails.cluster.value.name}`
      }
      return ''
    })

    function goToNode(payload) {
      router.push({
        name: 'node_details',
        params: { objectId: payload.object_id },
      })
    }

    const showNoDataMessage = computed(() => {
      const usage = useViewClusterDetails.chart_data_usage.value
      const prod_generation = useViewClusterDetails.chart_data_production_generation.value
      const prod_return = useViewClusterDetails.chart_data_production_return.value

      // Return true if all of the arrays are empty
      return usage.length === 0 && prod_generation.length === 0 && prod_return.length === 0
    })

    return {
      meta,
      pageTitle,
      useViewClusterDetails,
      goToNode,
      pageSuffix,
      confirm,
      useContentGuard,
      showNoDataMessage,
      useGlobal,
      useLookup,
    }
  },
  components: {
    nodeMapMultiple,
    cardChart,
    intervalSelector,
    featherIcon,
  },
}
</script>
