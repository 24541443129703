<template>
  <div class="p-6">
    <!-- header using base-page-title -->
    <base-page-title :title="pageTitle" :suffix="pageSuffix" :icon="meta.titleIcon"></base-page-title>

    <!-- content -->
    <div class="mt-8">
      <!-- Split panels -->
      <div class="flex flex-row">
        <span class="basis-1/2">
          <base-card class="text-gray-400" :loading="useViewHandler.loadHandler.states.get_details">
            <!-- edit button -->
            <base-button v-if="useContentGuard.canSee('object:write')" class="mb-2" @action="useViewHandler.openEdit">
              <featherIcon class="w-4 h-4" icon="EditIcon" />
            </base-button>
            <base-list :fields="useViewHandler.locationFields.value" :data="useViewHandler.location.value" />
          </base-card>
        </span>
        <span class="basis-1/2 ml-4">
          <!-- Map panel -->
          <base-card class="text-gray-400" :loading="useViewHandler.loadHandler.states.get_details">
            <nodeMapMultiple
              v-if="useViewHandler.nodes.value?.length > 0"
              :markerType="useViewHandler.nodes.value[0].medium"
              :nodes="useViewHandler.nodes.value"
            />
          </base-card>
        </span>
      </div>

      <!-- Nodes section -->
      <div class="mt-6">
        <base-page-title :title="$translate('Nodes')" />

        <baseTable
          :fields="useViewHandler.nodeFields.value"
          :records="useViewHandler.nodes.value"
          :records-per-page="25"
          :add-button="useContentGuard.canSee('object:write')"
          :edit-button="false"
          :delete-button="useContentGuard.canSee('object:write')"
          :clickAble="true"
          :clickCallback="goToNode"
          :loading="
            useViewHandler.loadHandler.states.get_details ||
            useViewHandler.loadHandler.states.assign_nodes ||
            useViewHandler.loadHandler.states.unassign_node
          "
          :hover-effect="true"
          leadIcon="CircleIcon"
          :search="false"
          @add="useViewHandler.openAddNodes"
          @delete="useViewHandler.unAssignNode"
          :sortable="true"
        />
      </div>

      <!-- modals -->
      <baseModal
        v-if="useViewHandler.showEdit.value"
        @close="useViewHandler.closeEdit"
        :title="$translate('Edit location')"
      >
        <baseForm :handler="useViewHandler.editForm" />

        <template v-slot:buttons>
          <div class="flex flex-row justify-end">
            <baseButton
              :disabled="!useViewHandler.editForm.isValid.value"
              @click="useViewHandler.edit"
              :loading="useViewHandler.loadHandler.states.edit_location"
            >
              {{ $translate('Save') }}
            </baseButton>
          </div>
        </template>
      </baseModal>

      <baseModal
        v-if="useViewHandler.showAddNodes.value"
        @close="useViewHandler.closeAddNodes"
        :title="$translate('Add nodes')"
        size="l"
      >
        <baseTable
          :fields="useViewHandler.nodeFields.value"
          :records="useViewHandler.unassigned_nodes.value"
          :records-per-page="15"
          :add-button="false"
          :edit-button="false"
          :delete-button="false"
          :selectable="true"
          :loading="useViewHandler.loadHandler.states.get_unassigned_nodes"
          :hover-effect="true"
          leadIcon="CircleIcon"
          :search="true"
          @selection="useViewHandler.setSelectedUnassignedNodes"
          :sortable="true"
        />

        <!-- {{ useViewHandler.unassigned_nodes_selected.value }} -->

        <template v-slot:buttons>
          <div class="flex flex-row justify-end">
            <baseButton @click="useViewHandler.assignNodes" :loading="useViewHandler.loadHandler.states.assign_nodes">
              {{ $translate('Add') }}
            </baseButton>
          </div>
        </template>
      </baseModal>
    </div>
  </div>
</template>

<script>
import { onMounted, computed } from 'vue'
import { useRoute } from 'vue-router'
import router from '@/router'
import viewHandlerLocationDetails from '@/use/viewHandlerLocationDetails'
import featherIcon from '@/components/extended/feather/featherIcon.vue'
import contentGuardHandler from '@/use/contentGuardHandler'
import nodeMapMultiple from '@/views/components/nodeMapMultiple.vue'

export default {
  setup() {
    const useViewHandler = viewHandlerLocationDetails()
    const route = useRoute()
    const useContentGuard = contentGuardHandler()
    const meta = computed(() => route.meta)

    const pageTitle = computed(() => {
      return meta.value.title
    })

    const pageSuffix = computed(() => {
      if (!useViewHandler.loadHandler.states.get_details) {
        return `${useViewHandler.location.value.name}`
      }
      return ''
    })

    onMounted(() => {
      useViewHandler.getDetails({
        object_id: route.params.objectId,
      })
    })

    function goToNode(payload) {
      router.push({
        name: 'node_details',
        params: { objectId: payload.object_id },
      })
    }

    return {
      useViewHandler,
      useContentGuard,
      goToNode,
      meta,
      pageTitle,
      pageSuffix,
    }
  },
  components: {
    featherIcon,
    nodeMapMultiple,
  },
}
</script>
