<template>
  <div class="rounded shadow-lg bg-white">
    <img v-if="image" class="w-full" :src="image" :alt="imageAlt" />
    <div class="px-4 py-4 text-gray-500">
      <!-- title -->
      <div v-if="title" class="flex mb-2">
        <featherIcon v-if="titleIcon" class="w-5 h-5 mr-2 my-auto" :icon="titleIcon" />
        <div class="text-xl">{{ title }}</div>
      </div>

      <!-- loader -->
      <div class="flex">
        <baseLoader :loading="loading" class="mx-auto my-auto" />
      </div>

      <!-- body -->
      <slot v-if="!loading"></slot>
    </div>
  </div>
</template>

<script>
import featherIcon from '@/components/extended/feather/featherIcon.vue'

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
    imageAlt: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    titleIcon: {
      type: String,
      default: null,
    },
  },
  setup() {},
  components: {
    featherIcon,
  },
}
</script>
