<template>
  <span id="henk">
    <!-- top bar -->
    <div class="flex flex-row justify-between">
      <!-- add button / export button / select all-->
      <div class="flex items-center">
        <baseButton
          class="px-4 mr-2 text-2xl py-1 my-2"
          size="large"
          v-if="addButton"
          @action="onAdd()"
          :disabled="loading"
          >+</baseButton
        >
      </div>

      <!-- box right -->
      <div class="flex flex-row">
        <!-- selection status -->
        <div class="my-auto mr-2">
          <div
            v-if="selectable && selectAllOption && totalSelected > 0"
            class="flex items-center text-sm text-gray-500"
          >
            <baseButton @action="onMultiEdit" variant="neutral" class="mx-2" v-if="multiEdit">
              <div class="flex flex-row">
                <span class="my-auto text-xs"> Bewerk {{ totalSelected }} items </span>
                <span class="mt-auto ml-2"><featherIcon class="w-4" icon="Edit3Icon" /></span>
              </div>
            </baseButton>
            <baseButtonDropDown
              :handler="useDropDown"
              variant="neutral"
              :displayText="`${totalSelected} rijen geselecteerd`"
            >
              <baseButtonDropDownItem @click="selectAllRecords"
                >Alle {{ records.length }} rijen selecteren</baseButtonDropDownItem
              >
              <baseButtonDropDownItem @click="deselectAll">Alles deselecteren</baseButtonDropDownItem>
            </baseButtonDropDown>
          </div>
        </div>

        <!-- Filter status -->
        <div class="my-auto mr-2">
          <div v-if="hasActiveFilters" class="flex items-center text-sm text-gray-500 my-2 rounded">
            <baseButtonDropDown
              :handler="useFilterDropDown"
              variant="neutral"
              :translate="false"
              :displayText="`${activeFilterCount} filters actief`"
            >
              <baseButtonDropDownItem @click="clearAllFilters">Alle filters wissen</baseButtonDropDownItem>
            </baseButtonDropDown>
          </div>
        </div>

        <!-- Export button -->
        <div v-if="exportButton" class="my-auto mr-2">
          <div class="flex items-center text-sm text-gray-500 my-2 rounded">
            <baseButtonDropDown
              :handler="useExportDropDown"
              variant="neutral"
              :translate="false"
              :icon="'SettingsIcon'"
            >
              <baseButtonDropDownItem @click="onExport">
                <div class="flex flex-row">
                  <featherIcon class="mr-2 w-4 h-4 text-gray-500" icon="DownloadIcon" />
                  <p class="whitespace-nowrap my-auto">Download xlsx</p>
                </div>
              </baseButtonDropDownItem>
            </baseButtonDropDown>
          </div>
        </div>

        <!-- Visible columns button -->
        <div v-if="showVisibleColumns" class="my-auto mr-2">
          <div class="flex items-center text-sm text-gray-500 my-2 rounded">
            <baseButtonDropDown :handler="useVisibleDropDown" variant="neutral" :translate="false" :icon="'EyeIcon'">
              <baseButtonDropDownItem v-for="(field, index) in tableFields" :key="index">
                <div class="flex flex-row gap-2">
                  <baseCheckbox :model-value="field.visible" @update:model-value="field.visible = !field.visible" />
                  <p class="whitespace-nowrap my-auto">{{ field.label }}</p>
                </div>
              </baseButtonDropDownItem>
            </baseButtonDropDown>
          </div>
        </div>

        <!-- search -->
        <div v-if="search" class="relative px-4 py-3 bg-white shadow-md rounded text-sm text-gray-500 my-2">
          <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
          </div>

          <input v-model="searchQuery" class="pl-8 focus:outline-none w-40" placeholder="zoeken" />
        </div>
      </div>
    </div>

    <!-- table -->
    <div class="relative shadow-md sm:rounded-lg">
      <table class="w-full text-sm text-left text-gray-500">
        <thead class="text-xs text-gray-700 bg-gray-100">
          <tr>
            <!-- Select column -->
            <th v-if="selectable" scope="col" class="px-6 py-3">
              <span>
                <baseCheckbox
                  class="text-gray-500"
                  :model-value="allSelectedOnCurrentPage"
                  @update:model-value="toggleSelectAllOnCurrentPage"
              /></span>
            </th>
            <!-- normal header cells -->
            <th scope="col" class="px-3 py-3" v-for="(field, index) in visibleFields" :key="index">
              <div v-if="sortable" class="flex items-center relative">
                <chevronupdown
                  v-if="index === sortFieldIndex"
                  @click="onSort(field, index)"
                  class="h-5 w-5 min-w-5 min-h-5 my-auto cursor-pointer"
                />
                <chevronupdown
                  v-else
                  @click="onSort(field, index)"
                  class="h-5 w-5 my-auto text-gray-400 cursor-pointer"
                />
                <span class="my-auto mx-1">{{ $translate(field.label) }}</span>

                <!-- Filter icon now conditionally rendered based on showFilters prop -->
                <div v-if="showFilters" v-click-outside="(event) => closeFilter(event, field)">
                  <featherIcon
                    class="w-4 h-4 ml-1 my-auto cursor-pointer"
                    :class="{
                      'text-sky-700': isColumnFiltered(field.key),
                      'text-gray-400': !isColumnFiltered(field.key),
                    }"
                    icon="FilterIcon"
                    @click="onShowFilter(field)"
                  />
                  <div
                    v-if="filterToShow === field.key"
                    class="absolute bg-white shadow-md p-2 rounded-md max-h-[20rem] overflow-y-auto w-64 z-50 text-gray-400"
                    style="top: 100%; left: 0"
                  >
                    <div
                      v-for="(record, index) in Object.entries(differentRecords[field.key])"
                      :key="index"
                      class="flex items-center space-x-2 py-1 hover:bg-gray-100 cursor-pointer"
                      @click="updateDifferentFilterRecords(field, record)"
                      :class="{
                        'opacity-50 cursor-not-allowed':
                          !availableFilterOptions[field.key].has(record[0]) && !record[1].selected.value,
                      }"
                      :title="
                        !availableFilterOptions[field.key].has(record[0]) && !record[1].selected.value
                          ? 'No matching records with current filters'
                          : ''
                      "
                    >
                      <baseCheckbox
                        class="flex-shrink-0 text-gray-500"
                        :model-value="record[1].selected.value"
                        :disabled="!availableFilterOptions[field.key].has(record[0]) && !record[1].selected.value"
                        @update:model-value="updateDifferentFilterRecords(field, record)"
                        @click.stop
                      />
                      <span class="text-gray-700 text-xs truncate">
                        {{ field.type === 'date' ? $filter.dateFromEpochDay(parseInt(record[0])) : record[0] }}
                      </span>
                      <span class="text-gray-500 text-xs ml-auto">
                        {{ record[1].count }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="px-3">
                {{ $translate(field.label) }}
              </div>
            </th>

            <!-- actionfield header cell -->
            <th v-if="actionField !== null" scope="col" class="px-6 py-3">{{ actionField }}</th>

            <!-- action header cell -->
            <th v-if="showActions" scope="col" class="px-6 py-3 max-w-[60]">Acties</th>
          </tr>
        </thead>
        <tbody>
          <!-- row looper -->
          <tr
            @click="onClick(record)"
            @auxclick="handleMiddleClick(record, $event)"
            :class="rowStyle + ' ' + getRowClass(record)"
            class="bg-white border-b"
            v-for="(record, index) in filteredRecordsPaginated"
            :key="index"
          >
            <!-- Select checkbox -->
            <td v-if="selectable" class="px-6 py-4">
              <baseCheckbox
                :model-value="isSelected(record)"
                @update:model-value="(value) => toggleSelect(record, value)"
                @click.stop
              />
            </td>
            <!-- normal cells -->
            <td class="px-6 py-4" v-for="(field, index) in visibleFields" :key="index">
              <component v-if="field.component" :is="field.component" :value="record[field.key]" />
              <span v-else-if="field.filter">
                {{ useFilter.filter(record[field.key], field.filter) }}
              </span>
              <span v-else-if="field.type === 'select'">
                {{ getSelectLabel(field, record[field.key]) }}
              </span>
              <span v-else>
                <span v-if="field.translate_value">{{ $translate(record[field.key]) }}</span>
                <span v-else>{{ record[field.key] }}</span>
              </span>
            </td>

            <!-- action field -->
            <td v-if="actionField !== null" class="px-6 py-4">
              <baseCheckbox
                :model-value="record[actionValue]"
                @update:model-value="(value) => onAction(record, value)"
              />
            </td>

            <!-- action cells -->
            <td v-if="showActions" class="px-6 py-4 max-w-[60]">
              <span v-if="exportRecordButton">
                <baseButton size="small" @action="onExportRecord(record)" class="mr-2 my-0" :disabled="loading">
                  <featherIcon class="w-4 h-4" icon="DownloadIcon" />
                </baseButton>
              </span>
              <span v-if="duplicateButton">
                <baseButton size="small" @action="onDuplicate(record[keyField])" class="mr-2 my-0" :disabled="loading">
                  <featherIcon class="w-4 h-4" icon="CopyIcon" />
                </baseButton>
              </span>
              <span v-if="editButton">
                <baseButton size="small" @action="onEdit(record, index)" class="mr-2 my-0" :disabled="loading">
                  <featherIcon class="w-4 h-4" icon="Edit3Icon" />
                </baseButton>
              </span>
              <span v-if="deleteButton">
                <baseButton
                  size="small"
                  @action="onDelete(record[keyField], index)"
                  class="my-0"
                  variant="danger"
                  :disabled="loading"
                >
                  <featherIcon class="w-4 h-4" icon="Trash2Icon" />
                </baseButton>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- no data box -->
      <span v-if="showNoRecords && !loading" class="flex justify-center bg-white">
        <span class="py-6 text-center text-xs text-gray-700">Geen gegevens gevonden</span>
      </span>

      <!-- loader -->
      <span v-if="loading" class="flex justify-center bg-white">
        <span class="py-6 text-center text-xs text-gray-700">
          <baseLoader :loading="loading" />
        </span>
      </span>
    </div>

    <!-- pagination -->
    <div v-if="pagination" class="flex flex-row-reverse" ref="footerRef">
      <div
        :class="onLastPage ? 'bg-gray-100 cursor-not-allowed' : 'bg-white'"
        class="px-4 py-3 bg-white shadow-md text-sm text-gray-500 my-2 cursor-pointer select-none"
        @click="movePpage(1)"
      >
        {{ '>' }}
      </div>
      <div class="px-4 py-3 bg-white shadow-md text-sm text-gray-500 my-2 mx-2">
        <!-- page index text -->
        {{ `${startRecord} - ${endRecord} van ${filteredRecords.length}` }}
      </div>
      <div
        v-if="!onFirstPage"
        class="px-4 py-3 bg-white shadow-md text-sm text-gray-500 cursor-pointer my-2 select-none"
        @click="movePpage(-1)"
      >
        {{ '<' }}
      </div>
    </div>

    <!-- confirmer -->
    <confimationModal :handler="confirm" />
  </span>
</template>

<script>
import { computed, ref, watch } from 'vue'
import confirmationHandler from '@/use/confirmationHandler'
import confimationModal from '@/components/extended/confirmationModal.vue'
import chevronupdown from '@/components/extended/chevronUpDown.vue'
import baseButtonDropDownHandler from '@/use/baseButtonDropDownHandler'
import filterHandler from '@/use/filterHandler'
import pillMainType from '@/views/components/pillMainType.vue'
//import pillLabels from '@/views/components/pillLabels.vue'
//import pillTrueFalse from '@/views/components/pillTrueFalse.vue'

const clickOutside = {
  mounted(el, binding) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event)
      }
    }
    document.addEventListener('click', el.clickOutsideEvent)
  },
  unmounted(el) {
    document.removeEventListener('click', el.clickOutsideEvent)
  },
}

export default {
  directives: {
    clickOutside,
  },
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    records: {
      type: Array,
      default: () => [],
    },
    pagination: {
      type: Boolean,
      default: true,
    },
    search: {
      type: Boolean,
      default: true,
    },
    searchType: {
      type: String,
      default: 'all',
    },
    recordsPerPage: {
      type: Number,
      default: 10,
    },
    addButton: {
      type: Boolean,
      default: false,
    },
    editButton: {
      type: Boolean,
      default: false,
    },
    deleteButton: {
      type: Boolean,
      default: false,
    },
    duplicateButton: {
      type: Boolean,
      default: false,
    },
    exportButton: {
      type: Boolean,
      default: false,
    },
    exportRecordButton: {
      type: Boolean,
      default: false,
    },
    keyField: {
      type: String,
      default: 'object_id',
    },
    clickAble: {
      type: Boolean,
      default: false,
    },
    clickCallback: {
      type: Function,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    selectAllOption: {
      type: Boolean,
      default: false,
    },
    exporting: {
      type: Boolean,
      default: false,
    },
    hoverEffect: {
      type: Boolean,
      default: false,
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    showFilters: {
      type: Boolean,
      default: true,
    },
    actionField: {
      type: String,
      default: null,
    },
    actionValue: {
      type: String,
      default: '',
    },
    multiEdit: {
      type: Boolean,
      default: false,
    },
    enableMiddleClick: {
      type: Boolean,
      default: false,
    },
    middleClickCallback: {
      type: Function,
      default: null,
    },
    showVisibleColumns: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['add', 'edit', 'delete', 'duplicate', 'export', 'export_record', 'selection', 'action', 'multi_edit'],
  setup(props, { emit }) {
    const pageIndex = ref(0)
    const searchQuery = ref('')
    const sortFieldIndex = ref(null)
    const sortField = ref({})
    const sortAsc = ref(true)
    const selected = ref({})
    const useDropDown = baseButtonDropDownHandler()
    const filterToShow = ref('')
    const useExportDropDown = baseButtonDropDownHandler()
    const useVisibleDropDown = baseButtonDropDownHandler()

    const tableFields = ref([])
    tableFields.value = props.fields.filter((field) => {
      return field.table !== false
    })

    tableFields.value.forEach((field) => {
      if (!Object.prototype.hasOwnProperty.call(field, 'visible')) {
        field.visible = true
      }
    })

    const filterState = ref({
      searchQuery: '',
      differentFilterRecords: {},
    })

    // handlers
    const confirm = confirmationHandler()
    const useFilter = filterHandler()

    const differentRecords = computed(() => {
      let currentRecords = props.records

      // Apply search filter first if there is a search query
      if (searchQuery.value) {
        const searchQueryLower = searchQuery.value.toLowerCase()
        currentRecords = currentRecords.filter((record) =>
          props.fields.some((field) => {
            if (field.type === 'string') {
              return record[field.key]?.toLowerCase().includes(searchQueryLower)
            }
            return false
          }),
        )
      }

      // Apply existing column filters except for the one being viewed
      if (Object.keys(filterState.value.differentFilterRecords).length > 0) {
        currentRecords = currentRecords.filter((record) => {
          return Object.entries(filterState.value.differentFilterRecords).every(([column, values]) => {
            // Skip the current column being viewed
            if (column === filterToShow.value) return true

            return Object.keys(values).some(
              (value) => String(record[column]) === String(value) || (record[column] === null && value === 'null'),
            )
          })
        })
      }

      // Build the available options from filtered records
      const records = {}
      props.fields.forEach((field) => {
        records[field.key] = {}

        // Count occurrences for each value in the filtered records
        currentRecords.forEach((record) => {
          const value = record[field.key]
          const stringValue = value === null ? 'null' : String(value)

          if (!records[field.key][stringValue]) {
            records[field.key][stringValue] = {
              count: 1,
              selected: ref(filterState.value.differentFilterRecords[field.key]?.[stringValue] !== undefined),
            }
          } else {
            records[field.key][stringValue].count++
          }
        })
      })

      return records
    })

    const differentFilterRecords = ref({})

    // fields filter
    const visibleFields = computed(() => {
      return tableFields.value.filter((field) => {
        if (field.visible === true) {
          return true
        }
        return false
      })
    })

    // Modify the filteredRecords computed to use AND logic
    const filteredRecords = computed(() => {
      let filtered = props.records

      // Apply column filters with AND logic
      if (Object.keys(filterState.value.differentFilterRecords).length !== 0) {
        filtered = filtered.filter((record) => {
          return Object.entries(filterState.value.differentFilterRecords).every(([column, values]) => {
            return Object.keys(values).some((value) => String(record[column]) === String(value))
          })
        })
      }

      // Apply search if there is a search query
      if (searchQuery.value) {
        const searchQueryLower = searchQuery.value.toLowerCase()
        filtered = filterByValue(filtered, searchQueryLower)
      }

      // Include selected records regardless of filters
      const selectedRecords = props.records.filter((record) => {
        const identifier = record[props.keyField]
        return identifier in selected.value
      })

      // Combine filtered and selected records, removing duplicates
      const combinedRecords = [...filtered]
      selectedRecords.forEach((record) => {
        const isDuplicate = combinedRecords.some((r) => r[props.keyField] === record[props.keyField])
        if (!isDuplicate) {
          combinedRecords.push(record)
        }
      })

      return combinedRecords
    })

    // sort
    const sortedRecords = computed(() => {
      if (sortFieldIndex.value || sortFieldIndex.value === 0) {
        const key = sortField.value.key
        const type = sortField.value.type
        const copy = JSON.parse(JSON.stringify(filteredRecords.value))
        return sort(copy, key, type)
      } else {
        return JSON.parse(JSON.stringify(filteredRecords.value))
      }
    })

    // pagination
    const filteredRecordsPaginated = computed(() => {
      if (props.pagination) {
        return paginate(sortedRecords.value)
      } else {
        return sortedRecords.value
      }
    })

    const onFirstPage = computed(() => pageIndex.value === 0)

    const onLastPage = computed(() => {
      return filteredRecords.value.length / props.recordsPerPage < pageIndex.value + 1
    })

    const lastPage = computed(() => {
      const lastpageNumber = filteredRecords.value.length / props.recordsPerPage
      return lastpageNumber ? Math.ceil(lastpageNumber) : 0
    })

    const startRecord = computed(() => {
      return pageIndex.value * props.recordsPerPage + 1
    })

    const endRecord = computed(() => {
      const totalRecords = filteredRecords.value.length
      let endRecord = (pageIndex.value + 1) * props.recordsPerPage
      if (endRecord > totalRecords) {
        endRecord = totalRecords
      }
      return endRecord
    })

    const allSelectedOnCurrentPage = computed(() => {
      return (
        filteredRecordsPaginated.value.length > 0 &&
        filteredRecordsPaginated.value.every((record) => isSelected(record))
      )
    })

    const totalSelected = computed(() => {
      return Object.keys(selected.value).length
    })

    const showActions = computed(() => {
      return props.editButton || props.deleteButton
    })

    const showNoRecords = computed(() => {
      return props.records.length === 0
    })

    const rowStyle = computed(() => {
      let style = ''
      if (props.clickAble) {
        style += 'cursor-pointer'
      }
      if (props.hoverEffect) {
        style += ' hover:bg-gray-100'
      }
      return style
    })

    function filterByValue(array, string) {
      return array.filter((record) =>
        props.fields.some((field) => {
          if (field.type === 'string') {
            const value = record[field.key]
            return typeof value === 'string' ? value.toLowerCase().includes(string) : false
          }
          return false
        }),
      )
    }
    function paginate(source) {
      const startIndex = pageIndex.value * props.recordsPerPage
      const endIndex = startIndex + props.recordsPerPage
      return source.slice(startIndex, endIndex)
    }

    function sort(source, key, type) {
      let returnValue = []
      if (type === 'string') {
        returnValue = source.sort((a, b) => {
          const aValue = (a[key] || '').toLowerCase()
          const bValue = (b[key] || '').toLowerCase()
          return aValue > bValue ? 1 : aValue < bValue ? -1 : 0
        })
      } else {
        returnValue = source.sort((a, b) => {
          const aValue = a[key] ?? Number.NEGATIVE_INFINITY
          const bValue = b[key] ?? Number.NEGATIVE_INFINITY
          return aValue > bValue ? 1 : aValue < bValue ? -1 : 0
        })
      }

      return sortAsc.value ? returnValue : returnValue.reverse()
    }

    function updateDifferentFilterRecords(column, record) {
      record[1].selected.value = !record[1].selected.value

      if (record[1].selected.value) {
        // Adding a filter
        if (!filterState.value.differentFilterRecords[column.key]) {
          filterState.value.differentFilterRecords[column.key] = {}
        }
        filterState.value.differentFilterRecords[column.key][record[0]] = record[1]
      } else {
        // Removing a filter
        delete filterState.value.differentFilterRecords[column.key][record[0]]
        if (Object.keys(filterState.value.differentFilterRecords[column.key]).length === 0) {
          delete filterState.value.differentFilterRecords[column.key]
        }
      }
    }

    // Add a helper computed for getting available values for each field
    const availableFilterOptions = computed(() => {
      let records = props.records

      // If we have active filters, first filter by them
      if (Object.keys(differentFilterRecords.value).length > 0) {
        records = records.filter((record) => {
          return Object.entries(differentFilterRecords.value).every(([column, values]) => {
            return Object.keys(values).some(
              (value) => String(record[column]) === value || (record[column] === null && value === 'null'),
            )
          })
        })
      }

      // Now count available options
      return records.reduce((acc, record) => {
        props.fields.forEach((field) => {
          if (!acc[field.key]) {
            acc[field.key] = new Set()
          }
          // Handle null values explicitly
          acc[field.key].add(record[field.key] === null ? 'null' : record[field.key])
        })
        return acc
      }, {})
    })

    function onAdd(prefill) {
      if (props.addButton) {
        emit('add', prefill)
      }
    }

    function onEdit(record, index) {
      if (props.editButton) {
        emit('edit', record, index)
      }
    }

    function onMultiEdit() {
      emit('multi_edit', Object.values(selected.value))
    }

    function onDuplicate(id) {
      if (props.duplicateButton) {
        emit('duplicate', id)
      }
    }

    function onExportRecord(payload) {
      if (props.exportRecordButton) {
        emit('export_record', payload)
      }
    }

    async function onDelete(id, index) {
      if (props.deleteButton) {
        const ok = await confirm.open({
          title: 'Object verwijderen?',
          message: 'deleteObject',
        })
        if (ok) {
          emit('delete', id, index)
        }
      }
    }

    function onExport() {
      if (props.exportButton) {
        emit('export', {
          records: sortedRecords.value,
          fields: visibleFields.value,
        })
      }
    }

    function onAction(record, value) {
      emit('action', record, value)
    }

    function toggleSelectAllOnCurrentPage(value) {
      filteredRecordsPaginated.value.forEach((record) => {
        const identifier = record[props.keyField]
        if (value) {
          selected.value[identifier] = record
        } else {
          delete selected.value[identifier]
        }
      })
      emitSelection()
    }

    function toggleSelect(record, value) {
      const identifier = record[props.keyField]
      if (value) {
        selected.value[identifier] = record
      } else {
        delete selected.value[identifier]
      }
      emitSelection()
    }

    function isSelected(record) {
      const identifier = record[props.keyField]
      return identifier in selected.value
    }

    function emitSelection() {
      emit('selection', selected.value)
    }

    function getRowClass(record) {
      return isSelected(record) ? '!bg-blue-100' : 'bg-white'
    }

    function onClick(record) {
      if (props.selectable) {
        toggleSelect(record, !isSelected(record))
      } else if (props.clickAble) {
        props.clickCallback(record)
      }
    }

    function onSort(field, index) {
      sortField.value = field
      if (sortFieldIndex.value === index) {
        sortAsc.value = !sortAsc.value
      } else {
        sortFieldIndex.value = index
        sortAsc.value = true
      }
    }

    function selectAllRecords() {
      props.records.forEach((record) => {
        const identifier = record[props.keyField]
        selected.value[identifier] = record
      })
      emitSelection()
    }

    function deselectAll() {
      selected.value = {}
      emitSelection()
    }

    function onShowFilter(field) {
      filterToShow.value = filterToShow.value === field.key ? '' : field.key
    }

    function closeFilter(event, field) {
      if (filterToShow.value === field.key) {
        filterToShow.value = ''
      }
    }

    function handleMiddleClick(record, event) {
      if (event.button === 1 && props.middleClickCallback) {
        event.preventDefault()
        props.middleClickCallback(record)
      }
    }

    function getSelectLabel(field, value) {
      if (field.options) {
        const option = field.options.find((opt) => opt.value === value)
        return option ? option.label : value
      }
      return value
    }

    function movePpage(direction) {
      if (direction === -1 && !onFirstPage.value) {
        pageIndex.value += direction
      } else if (direction === 1 && !onLastPage.value) {
        pageIndex.value += direction
      }
    }

    // page reset watcher
    watch(
      () => filterState.value,
      () => {
        pageIndex.value = 0
      },
      { deep: true },
    )

    watch(
      () => searchQuery.value,
      (newValue) => {
        filterState.value.searchQuery = newValue
      },
    )

    // Add another dropdown handler
    const useFilterDropDown = baseButtonDropDownHandler()

    // Add computed properties for filter status
    const hasActiveFilters = computed(() => {
      return Object.keys(filterState.value.differentFilterRecords).length > 0
    })

    const activeFilterCount = computed(() => {
      return Object.values(filterState.value.differentFilterRecords).reduce((total, columnFilters) => {
        return total + Object.keys(columnFilters).length
      }, 0)
    })

    // Add function to clear all filters
    function clearAllFilters() {
      // Clear all filter selections in differentRecords
      Object.values(differentRecords.value).forEach((column) => {
        Object.values(column).forEach((record) => {
          record.selected.value = false
        })
      })
      // Clear the filter state
      filterState.value.differentFilterRecords = {}
      filterState.value.searchQuery = ''
      searchQuery.value = ''
    }
    function isColumnFiltered(columnKey) {
      return Object.keys(filterState.value.differentFilterRecords?.[columnKey] || {}).length > 0
    }

    return {
      isColumnFiltered,
      filteredRecords,
      filteredRecordsPaginated,
      pageIndex,
      movePpage,
      onFirstPage,
      onLastPage,
      lastPage,
      searchQuery,
      onAdd,
      onEdit,
      onDelete,
      showActions,
      showNoRecords,
      visibleFields,
      confirm,
      onClick,
      rowStyle,
      onDuplicate,
      onExport,
      sortFieldIndex,
      onSort,
      sortedRecords,
      onExportRecord,
      selected,
      allSelectedOnCurrentPage,
      toggleSelectAllOnCurrentPage,
      toggleSelect,
      isSelected,
      getRowClass,
      totalSelected,
      useDropDown,
      deselectAll,
      selectAllRecords,
      onAction,
      onMultiEdit,
      differentRecords,
      updateDifferentFilterRecords,
      onShowFilter,
      filterToShow,
      closeFilter,
      getSelectLabel,
      handleMiddleClick,
      startRecord,
      endRecord,
      useFilter,
      hasActiveFilters,
      activeFilterCount,
      useFilterDropDown,
      clearAllFilters,
      availableFilterOptions,
      filterState,
      useExportDropDown,
      useVisibleDropDown,
      tableFields,
    }
  },
  components: {
    confimationModal,
    chevronupdown,
    //pillLabels,
    pillMainType,
    //pillTrueFalse,
  },
}
</script>
