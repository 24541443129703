import { computed, ref } from 'vue'
import loadingHandler from '@/use/loadingHandler'
import L from 'leaflet'
import markerHandler from '@/views/components/mapIcons/elecricity'

export default () => {
  const loadHandler = loadingHandler()
  const editState = ref('neutral_no_location')
  const originalState = ref('')
  const geometry = ref({})
  const useMarkers = markerHandler()
  const nodeID = ref({})
  const searchMarker = ref({})

  const buttonText = computed(() => {
    if (editState.value === 'editing') {
      return 'Save'
    }
    if (editState.value === 'neutral_location') {
      return 'Edit geometry'
    }
    if (editState.value === 'neutral_no_location') {
      return 'Add geometry'
    }
    if (editState.value === 'success') {
      return 'Success'
    }
    if (editState.value === 'error') {
      return 'Error'
    }
    return ''
  })

  const buttonColour = computed(() => {
    if (editState.value === 'editing') {
      return 'warning'
    }
    if (editState.value === 'neutral_location') {
      return 'primary'
    }
    if (editState.value === 'neutral_no_location') {
      return 'primary'
    }
    if (editState.value === 'success') {
      return 'success'
    }
    if (editState.value === 'error') {
      return 'danger'
    }
    return 'primary'
  })

  function startEdit() {
    setState('editing')
  }

  function editGeometry() {
    loadHandler.setLoadingState('edit_geometry', true)
  }

  function setState(state) {
    editState.value = state
  }

  function setStateOriginal(state) {
    originalState.value = state
  }

  function setGeometry(value) {
    if (editState.value === 'editing') {
      geometry.value = value
    }
  }

  function getMarker(type) {
    const selector = {
      Electricity: useMarkers.electricityMarker,
      Gas: useMarkers.gasMarker,
      Water: useMarkers.waterMarker,
      Heat: useMarkers.heatMarker,
      HeatCost: useMarkers.heatCostMarker,
    }

    return selector[type]
  }

  function updateMarker(marker, map, lat, lng, markerType) {
    if (!marker) {
      marker = L.marker([lat, lng], { icon: getMarker(markerType) })
      marker.addTo(map)
    } else {
      var newLatLng = new L.LatLng(lat, lng)
      marker.setLatLng(newLatLng)
    }
  }

  function addMarker(map, lat, lng, markerType) {
    const newMarker = L.marker([lat, lng], { icon: getMarker(markerType) })
    newMarker.addTo(map)
    return newMarker
  }

  function panMap(map, lat, lng, zoom = null) {
    if (zoom !== null) {
      map.setView([lat, lng], zoom)
    } else {
      map.setView([lat, lng])
    }
  }

  function addSearchMarker(map, lat, lng) {
    map.removeLayer(searchMarker.value)
    searchMarker.value = L.marker([lat, lng], { icon: useMarkers.targetMarker })
    searchMarker.value.addTo(map)
  }

  function removeSearchMarker(map) {
    map.removeLayer(searchMarker.value)
  }

  function removeMarker(map, marker) {
    map.removeLayer(marker)
  }

  function renderMap(target, name, clickable, settings) {
    nodeID.value = settings.value.nodeId

    const map = L.map(target, { attributionControl: false }).setView(
      [settings.value.lat, settings.value.lng],
      settings.value.zoom,
    )

    let marker = null

    if (settings.value.geometry) {
      marker = L.marker([settings.value.lat, settings.value.lng], { icon: getMarker(settings.value.markerType) })
      marker.addTo(map)
    }

    L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
      maxZoom: 19,
      id: name,
    }).addTo(map)

    setInterval(function () {
      map.invalidateSize()
    }, 100)

    if (clickable) {
      map.on('click', function (e) {
        const lat = e.latlng.lat
        const lng = e.latlng.lng

        if (editState.value === 'editing') {
          updateMarker(marker, map, lat, lng, settings.value.markerType)

          setGeometry({
            lat: e.latlng.lat,
            lng: e.latlng.lng,
          })
        }
      })
    }

    return {
      map,
      marker,
    }
  }

  return {
    buttonText,
    buttonColour,
    startEdit,
    loadHandler,
    editGeometry,
    setState,
    setStateOriginal,
    setGeometry,
    editState,
    originalState,
    renderMap,
    geometry,
    updateMarker,
    panMap,
    addSearchMarker,
    removeSearchMarker,
    removeMarker,
    addMarker,
  }
}
