<template>
  <span :class="pillClass">{{ displayValue }}</span>
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'pillTrueFalse',
  props: {
    value: {
      type: [Boolean, null],
      required: true,
      default: false,
      validator: (value) => {
        return value === true || value === false || value === null
      },
    },
  },
  setup(props) {
    const displayValue = computed(() => {
      if (props.value === null) return 'false'
      return props.value
    })

    const pillClass = computed(() => {
      const baseClasses = 'px-2 py-1 rounded-full text-xs font-medium'

      if (props.value === true) {
        return `${baseClasses} bg-green-700/75 text-white`
      }

      return `${baseClasses} bg-gray-200/75 text-black`
    })

    return {
      pillClass,
      displayValue,
    }
  },
}
</script>
