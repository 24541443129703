<template>
  <div class="text-gray-500">
    <div class="flex flex-row justify-between items-center">
      <!-- label -->
      <div class="align-middle">
        <span v-if="label">{{ $translate(label) }}</span>
      </div>

      <!-- options -->
      <div class="flex flex-row">
        <base-button
          v-if="showInfo"
          variant="neutral"
          :icon="'InfoIcon'"
          class="text-gray-500 mr-2"
          @click="openInfoModal"
        >
          <featherIcon class="w-4 h-4 text-gray-500" icon="InfoIcon" />
        </base-button>

        <base-button
          variant="neutral"
          :icon="'SettingsIcon'"
          class="text-gray-500 mr-2"
          v-if="scatter"
          @click="toggleChart"
        >
          <scatterPlotIcon class="w-5 h-5 text-gray-500" />
        </base-button>

        <base-button variant="neutral" class="text-gray-500 mr-2" @click="toggleTableView">
          <featherIcon v-if="showTableView" class="w-4 h-4 text-gray-500" icon="BarChart2Icon" />
          <featherIcon v-else class="w-4 h-4 text-gray-500" icon="TableIcon" />
        </base-button>

        <base-button-drop-down
          :alignRight="true"
          :handler="optionsDropDown"
          variant="neutral"
          :icon="'SettingsIcon'"
          class="mr-2"
        >
          <base-button-drop-down-item @click="seriesToExcel({ title: exportTitle })">
            <div class="flex flex-row">
              <featherIcon class="mr-2 w-4 h-4 text-gray-500" icon="DownloadIcon" />
              <p class="whitespace-nowrap my-auto">Download xlsx</p>
            </div>
          </base-button-drop-down-item>
        </base-button-drop-down>

        <base-button variant="neutral" :icon="'MaximizeIcon'" class="text-gray-500" @click="openModal">
          <featherIcon class="w-4 h-4 text-gray-500" icon="MaximizeIcon" />
        </base-button>
      </div>
    </div>

    <div v-if="!showTableView">
      <div v-if="!isScatterPlot">
        <vue-highcharts
          class="mt-2"
          type="chart"
          :options="options"
          :redrawOnUpdate="true"
          :oneToOneUpdate="false"
          :animateOnUpdate="true"
          ref="root"
        />
      </div>

      <div v-else>
        <vue-highcharts
          class="mt-2"
          :type="type"
          :options="scatterOptions"
          :redrawOnUpdate="true"
          :oneToOneUpdate="false"
          :animateOnUpdate="true"
          ref="scatterRoot"
        />
      </div>
    </div>

    <div v-else class="mt-2">
      <baseTable
        :fields="tableFields"
        :records="tableRecords"
        :records-per-page="10"
        :add-button="false"
        :edit-button="false"
        :delete-button="false"
        :export-button="false"
        :loading="false"
        :sortable="true"
        :search="false"
        :showVisibleColumns="false"
      />
    </div>

    <!-- Chart Modal -->
    <base-modal v-if="showModal" @close="closeModal" :title="label || 'Chart'" size="xl" height="l">
      <div class="w-full h-full">
        <vue-highcharts
          class="h-full"
          type="chart"
          :options="isScatterPlot ? scatterOptions : options"
          :redrawOnUpdate="true"
          :oneToOneUpdate="false"
          :animateOnUpdate="true"
        />
      </div>
    </base-modal>

    <!-- Info Modal -->
    <base-modal v-if="showInfoModal" @close="closeInfoModal" :title="''" size="m">
      <slot name="info-content">
        <component v-if="infoComponent" :is="infoComponent" v-bind="infoComponentProps" />
      </slot>
    </base-modal>
  </div>
</template>

<script>
import { nextTick, ref, watch, computed } from 'vue'
import VueHighcharts from '@/use/highCharts'
import baseButtonDropDownHandler from '@/use/baseButtonDropDownHandler'
import ExcelJS from 'exceljs'
import scatterPlotIcon from '@/components/svg/scatterPlotIcon.vue'

export default {
  props: {
    type: {
      type: String,
      default: 'chart',
    },
    label: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    exportUnit: {
      type: String,
      default: '',
    },
    exportTitle: {
      type: String,
      default: '',
    },
    exportParserX: {
      required: false,
      default: null,
    },
    exportParserY: {
      required: false,
      default: null,
    },
    scatter: {
      type: Boolean,
      default: false,
    },
    scatterOptions: {
      type: Object,
      default: () => ({}),
    },
    showInfo: {
      type: Boolean,
      default: false,
    },
    infoTitle: {
      type: String,
      default: '',
    },
    infoComponent: {
      type: [Object, null],
      default: null,
    },
    infoComponentProps: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const optionsDropDown = baseButtonDropDownHandler()
    const isScatterPlot = ref(false)
    const showModal = ref(false)
    const showInfoModal = ref(false)
    const showTableView = ref(false)

    const currentSeries = computed(() => {
      return isScatterPlot.value ? props.scatterOptions.series : props.options.series
    })

    const isTimeSeriesData = computed(() => {
      if (!currentSeries.value?.[0]?.data?.[0]) return false
      return Array.isArray(currentSeries.value[0].data[0])
    })

    const tableFields = computed(() => {
      return [
        // {
        //   label: 'Serie',
        //   key: 'series',
        //   type: 'string',
        // },
        {
          label: isTimeSeriesData.value ? 'Date' : 'Hour',
          key: isTimeSeriesData.value ? 'date' : 'hour',
          type: 'string',
        },
        {
          label: 'Value',
          key: 'value',
          type: 'number',
        },
        {
          label: 'Unit',
          key: 'unit',
          type: 'string',
        },
      ]
    })

    const tableRecords = computed(() => {
      const records = []
      if (!currentSeries.value) return records

      currentSeries.value.forEach((serie) => {
        const serieName = serie.name || 'Unknown'
        serie.data.forEach((point, index) => {
          records.push({
            series: serieName,
            date: isTimeSeriesData.value ? formatTimestamp(point[0]) : undefined,
            hour: !isTimeSeriesData.value ? `${index.toString().padStart(2, '0')}:00` : undefined,
            value: isTimeSeriesData.value ? formatValue(point[1]) : formatValue(point),
            unit: props.exportUnit,
          })
        })
      })
      return records
    })

    watch(
      () => props.scatter,
      (newVal) => {
        if (!newVal && isScatterPlot.value) {
          isScatterPlot.value = false
        }
      },
    )

    function toggleChart() {
      isScatterPlot.value = !isScatterPlot.value
    }

    function toggleTableView() {
      showTableView.value = !showTableView.value
    }

    function formatValue(value) {
      if (value === null || value === undefined) return ''
      return typeof value === 'number' ? value.toFixed(2) : value
    }

    function formatTimestamp(timestamp) {
      try {
        if (typeof timestamp === 'string' && timestamp.includes('-')) {
          return timestamp
        }
        if (typeof timestamp === 'number') {
          return `${timestamp.toString().padStart(2, '0')}:00`
        }
        const date = new Date(timestamp)
        const day = String(date.getDate()).padStart(2, '0')
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const year = date.getFullYear()
        const hour = String(date.getHours()).padStart(2, '0')
        const minutes = String(date.getMinutes()).padStart(2, '0')
        return `${day}-${month}-${year}, ${hour}:${minutes}`
      } catch (error) {
        console.error('Error formatting timestamp:', timestamp, error)
        return timestamp
      }
    }

    async function seriesToExcel({ title = 'SimpleWEG - Export', worksheetName = 'Data' } = {}) {
      await nextTick()
      const series = isScatterPlot.value ? props.scatterOptions.series : props.options.series

      if (!series) {
        console.error('No valid data found for export')
        return
      }

      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet(worksheetName)

      const formatValue = (value) => {
        try {
          if (value === null || value === undefined) {
            return null
          }

          if (typeof value === 'number') {
            return value
          }

          const numberValue = parseFloat(value)
          if (!isNaN(numberValue)) {
            return numberValue
          }

          return value
        } catch (error) {
          console.error('Error formatting value:', value, error)
          return null
        }
      }

      const formatTimestamp = (timestamp) => {
        try {
          if (typeof timestamp === 'string' && timestamp.includes('-')) {
            return timestamp
          }

          const date = new Date(timestamp)
          const day = String(date.getDate()).padStart(2, '0')
          const month = String(date.getMonth() + 1).padStart(2, '0')
          const year = date.getFullYear()
          const hour = String(date.getHours()).padStart(2, '0')
          const minutes = String(date.getMinutes()).padStart(2, '0')

          return `${day}-${month}-${year}, ${hour}:${minutes}`
        } catch (error) {
          console.error('Error formatting timestamp:', timestamp, error)
          return timestamp
        }
      }

      const isTimeSeriesData = (data) => {
        return Array.isArray(data[0]) && data[0].length === 2
      }

      const columns = isTimeSeriesData(series[0].data)
        ? [
            { header: 'Serie', key: 'series', width: 15 },
            { header: 'Datum', key: 'date', width: 20 },
            { header: 'Waarde', key: 'value', width: 15, style: { numFmt: '0.00' } },
            { header: 'Eenheid', key: 'unit', width: 10 },
          ]
        : [
            { header: 'Serie', key: 'series', width: 15 },
            { header: 'Uur', key: 'hour', width: 10 },
            { header: 'Waarde', key: 'value', width: 15, style: { numFmt: '0.00' } },
            { header: 'Eenheid', key: 'unit', width: 10 },
          ]

      worksheet.columns = columns

      if (isTimeSeriesData(series[0].data)) {
        series.forEach((serie) => {
          const serieName = serie.name || 'Onbekend'
          serie.data.forEach((row) => {
            try {
              if (!row || !Array.isArray(row)) return
              const [timestamp, value] = row

              const rowData = {
                series: serieName,
                date: props.exportParserX ? props.exportParserX(timestamp) : formatTimestamp(timestamp),
                value: formatValue(value),
                unit: props.exportUnit,
              }

              const excelRow = worksheet.addRow(rowData)
              excelRow.getCell('value').numFmt = '0.00'
            } catch (error) {
              console.error('Error adding time series row:', row, error)
            }
          })
        })
      } else {
        series.forEach((serie) => {
          const serieName = serie.name || 'Onbekend'
          serie.data.forEach((value, index) => {
            try {
              const hourString = `${index.toString().padStart(2, '0')}:00`

              const rowData = {
                series: serieName,
                hour: hourString,
                value: formatValue(value),
                unit: props.exportUnit,
              }

              const excelRow = worksheet.addRow(rowData)
              excelRow.getCell('value').numFmt = '0.00'
            } catch (error) {
              console.error('Error adding hourly row:', value, error)
            }
          })
        })
      }

      const headerRow = worksheet.getRow(1)
      headerRow.font = { bold: true }
      headerRow.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFE6E6E6' },
      }

      worksheet.columns.forEach((column) => {
        if (!column || !column.header) return
        let maxLength = column.header.length
        column.eachCell({ includeEmpty: true }, (cell) => {
          const length = cell.value ? cell.value.toString().length : 10
          maxLength = Math.max(maxLength, length)
        })
        column.width = maxLength + 2
      })

      try {
        const buffer = await workbook.xlsx.writeBuffer()
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        const url = window.URL.createObjectURL(blob)
        const anchor = document.createElement('a')
        anchor.href = url
        anchor.download = `${title || 'Export'}.xlsx`
        anchor.click()

        window.URL.revokeObjectURL(url)
        anchor.remove()
      } catch (error) {
        console.error('Export failed:', error)
      }
    }

    function openModal() {
      showModal.value = true
    }

    function closeModal() {
      showModal.value = false
    }

    function openInfoModal() {
      showInfoModal.value = true
    }

    function closeInfoModal() {
      showInfoModal.value = false
    }

    return {
      optionsDropDown,
      seriesToExcel,
      toggleChart,
      toggleTableView,
      isScatterPlot,
      showTableView,
      currentSeries,
      isTimeSeriesData,
      formatValue,
      formatTimestamp,
      openModal,
      closeModal,
      showModal,
      showInfoModal,
      openInfoModal,
      closeInfoModal,
      tableFields,
      tableRecords,
    }
  },
  components: {
    VueHighcharts,
    scatterPlotIcon,
  },
}
</script>
