import lookupHandler from '@/use/lookupHandler'
import filterHandler from '@/use/filterHandler'
import translationHandler from '@/plugins/i18n/translationHandler'
import Highcharts from 'highcharts'
import Boost from 'highcharts/modules/boost'
Boost(Highcharts)

// import { format } from 'date-fns'
// import { DateTime } from 'luxon'

export default () => {
  const useLookup = lookupHandler()
  const useFilter = filterHandler()
  const useTranslate = translationHandler()

  // Add new helper function for processing hourly data
  // For weekday comparison, update processHourlyData function
  function processHourlyData(data) {
    const DEBUG_WEEKDAY = false // Toggle debug output
    const debugData = {
      hourGroups: {},
      dayMappings: {},
      rawSample: [],
    }

    if (!Array.isArray(data)) {
      console.error('Data must be an array', data)
      return {
        hourlyAggregation: [],
        weekdayHourlyData: {},
      }
    }

    // Store raw sample for debugging
    debugData.rawSample = data.slice(0, 5)

    // Process each data point with proper timezone handling
    const processedData = data
      .map(([dateStr, value]) => {
        try {
          if (!dateStr || typeof dateStr !== 'string') {
            console.error('Invalid date string:', dateStr)
            return null
          }

          const utcDate = new Date(dateStr)
          const localHour = utcDate.getHours()
          const localDay = utcDate.getDay()
          const adjustedDay = (localDay + 6) % 7 // Convert Sunday (0) to 6

          // Store debug information
          const debugKey = `${utcDate.toISOString()}`
          debugData.dayMappings[debugKey] = {
            originalTimestamp: dateStr,
            localTime: utcDate.toLocaleString('nl-NL'),
            localHour,
            localDay: ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'][localDay],
            adjustedDay: ['Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag', 'Zondag'][adjustedDay],
            value,
          }

          return {
            date: utcDate,
            dayOfWeek: adjustedDay,
            hour: localHour,
            value: value,
          }
        } catch (error) {
          console.error('Error parsing date:', dateStr, error)
          return null
        }
      })
      .filter(Boolean)

    // Initialize data structures
    const days = ['Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag', 'Zondag']
    const weekdayData = {}

    // Initialize weekday data structure
    days.forEach((day) => {
      weekdayData[day] = Array(24)
        .fill(0)
        .map((_, hour) => ({
          hour,
          value: 0,
          count: 0,
        }))
    })

    // Aggregate data by actual day of week
    processedData.forEach((point) => {
      if (point) {
        const weekday = days[point.dayOfWeek]
        weekdayData[weekday][point.hour].value += point.value
        weekdayData[weekday][point.hour].count++

        // Add to debug groups
        const debugKey = `${weekday}-${point.hour}`
        if (!debugData.hourGroups[debugKey]) {
          debugData.hourGroups[debugKey] = []
        }
        debugData.hourGroups[debugKey].push({
          timestamp: point.date.toLocaleString('nl-NL'),
          value: point.value,
        })
      }
    })

    // Calculate hourly aggregation (average by hour across all days)
    const hourlyAggregation = Array(24)
      .fill(0)
      .map((_, hour) => {
        const hourData = processedData.filter((p) => p && p.hour === hour)
        return {
          hour,
          average: hourData.length > 0 ? hourData.reduce((sum, p) => sum + p.value, 0) / hourData.length : 0,
        }
      })

    // Calculate averages for each weekday
    const weekdayHourlyData = {}
    days.forEach((day) => {
      weekdayHourlyData[day] = weekdayData[day].map((hourData) => {
        const average = hourData.count > 0 ? hourData.value / hourData.count : 0

        // Add calculation details to debug
        if (DEBUG_WEEKDAY && hourData.count > 0) {
          debugData.hourGroups[`${day}-${hourData.hour}`] = {
            ...debugData.hourGroups[`${day}-${hourData.hour}`],
            average,
            count: hourData.count,
            total: hourData.value,
          }
        }

        return {
          hour: hourData.hour,
          value: average,
        }
      })
    })

    if (DEBUG_WEEKDAY) {
      console.group('Weekday Data Processing Debug')
      console.log('Raw Data Sample:', debugData.rawSample)

      console.group('Sample Day/Hour Groups')
      Object.entries(debugData.hourGroups)
        .slice(0, 5) // Show first 5 groups as example
        .forEach(([dayHour, data]) => {
          console.log(`${dayHour}:`, data)
        })
      console.groupEnd()

      console.group('Sample Averages')
      Object.entries(weekdayHourlyData)
        .slice(0, 2) // Show first 2 days as example
        .forEach(([day, hours]) => {
          console.group(day)
          hours.slice(0, 3).forEach((hourData) => {
            // Show first 3 hours
            console.log(`${hourData.hour}:00 - Value: ${hourData.value.toFixed(2)}`)
          })
          console.groupEnd()
        })
      console.groupEnd()

      console.groupEnd()
    }

    const VERIFY_HOURS = false // Toggle for this specific check

    if (VERIFY_HOURS) {
      // Take a sample of data points and show both UTC and local times
      const verificationSample = data.slice(0, 10).map(([dateStr, value]) => {
        const utcDate = new Date(dateStr)
        return {
          original_utc: dateStr,
          utc_hour: utcDate.getUTCHours(),
          local_time: utcDate.toLocaleString('nl-NL'),
          local_hour: utcDate.getHours(),
          value: value,
          // Show which group it will go into
          groups_into: {
            day: ['Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag', 'Zondag'][
              (utcDate.getDay() + 6) % 7
            ],
            hour: utcDate.getHours(),
          },
        }
      })

      console.group('Hour Grouping Verification')
      console.log('Sample of hour mappings:')
      verificationSample.forEach((sample, i) => {
        console.log(`Point ${i + 1}:`, {
          'UTC timestamp': sample.original_utc,
          'UTC hour': `${sample.utc_hour}:00`,
          'Groups into local': `${sample.groups_into.day} ${sample.groups_into.hour}:00`,
          'Full local time': sample.local_time,
        })
      })
      console.groupEnd()
    }

    // Add this to your processHourlyData function
    const VERIFY_DST = false

    if (VERIFY_DST) {
      verifyDSTTransitions(data)
    }

    return {
      hourlyAggregation,
      weekdayHourlyData,
    }
  }

  // Add this function to help with testing DST transitions
  function verifyDSTTransitions(data) {
    // First get the actual range from our data
    const dates = data.map(([dateStr]) => new Date(dateStr))
    const minDate = new Date(Math.min(...dates.map((d) => d.getTime())))
    const maxDate = new Date(Math.max(...dates.map((d) => d.getTime())))

    console.group('DST Transitions Verification')

    console.log('Dataset range:', {
      from: minDate.toLocaleString('nl-NL'),
      to: maxDate.toLocaleString('nl-NL'),
    })

    // Only check years that are actually in our dataset
    const startYear = minDate.getFullYear()
    const endYear = maxDate.getFullYear()
    const yearsToCheck = []

    for (let year = startYear; year <= endYear; year++) {
      yearsToCheck.push(year)
    }

    yearsToCheck.forEach((year) => {
      // Last Sunday in March
      const springDate = new Date(year, 2, 31) // March 31
      springDate.setDate(springDate.getDate() - springDate.getDay()) // Adjust to last Sunday

      // Last Sunday in October
      const fallDate = new Date(year, 9, 31) // October 31
      fallDate.setDate(fallDate.getDate() - fallDate.getDay()) // Adjust to last Sunday

      console.group(`DST Transitions ${year}`)

      // Only check if the transition date falls within our dataset
      if (springDate >= minDate && springDate <= maxDate) {
        console.group(`Spring Forward - ${springDate.toLocaleDateString('nl-NL')}`)
        const springData = data.filter(([dateStr]) => {
          const date = new Date(dateStr)
          return date.toDateString() === springDate.toDateString()
        })

        if (springData.length > 0) {
          springData.forEach(([dateStr, value]) => {
            const date = new Date(dateStr)
            console.log({
              UTC: dateStr,
              'UTC hour': date.getUTCHours(),
              Local: date.toLocaleString('nl-NL'),
              'Local hour': date.getHours(),
              Value: value,
            })
          })
        } else {
          console.log('No data found for this transition')
        }
        console.groupEnd()
      }

      if (fallDate >= minDate && fallDate <= maxDate) {
        console.group(`Fall Back - ${fallDate.toLocaleDateString('nl-NL')}`)
        const fallData = data.filter(([dateStr]) => {
          const date = new Date(dateStr)
          return date.toDateString() === fallDate.toDateString()
        })

        if (fallData.length > 0) {
          fallData.forEach(([dateStr, value]) => {
            const date = new Date(dateStr)
            console.log({
              UTC: dateStr,
              'UTC hour': date.getUTCHours(),
              Local: date.toLocaleString('nl-NL'),
              'Local hour': date.getHours(),
              Value: value,
            })
          })
        } else {
          console.log('No data found for this transition')
        }
        console.groupEnd()
      }

      console.group('Summary')
      const springData = data.filter(([dateStr]) => {
        const date = new Date(dateStr)
        return date.toDateString() === springDate.toDateString()
      })
      const fallData = data.filter(([dateStr]) => {
        const date = new Date(dateStr)
        return date.toDateString() === fallDate.toDateString()
      })

      console.log('Spring Forward:', {
        'Data points': springData.length,
        'Missing 2AM hour':
          springData.length > 0 ? !springData.some(([dateStr]) => new Date(dateStr).getHours() === 2) : 'No data',
        'Hours found':
          springData.length > 0
            ? [...new Set(springData.map(([dateStr]) => new Date(dateStr).getHours()))].sort((a, b) => a - b)
            : [],
      })
      console.log('Fall Back:', {
        'Data points': fallData.length,
        'Double 2AM exists':
          fallData.length > 0 ? fallData.filter(([dateStr]) => new Date(dateStr).getHours() === 2).length : 'No data',
        'Hours found':
          fallData.length > 0
            ? [...new Set(fallData.map(([dateStr]) => new Date(dateStr).getHours()))].sort((a, b) => a - b)
            : [],
      })
      console.groupEnd()

      console.groupEnd()
    })

    console.groupEnd()
  }

  // Set Highcharts to use UTC
  Highcharts.setOptions({
    time: {
      useUTC: false,
    },
  })

  function getOptions(type, data, title, interval = null) {
    const selector = {
      Electricity: {
        chart: {
          type: 'column',
          zooming: {
            mouseWheel: {
              enabled: false,
            },
          },
        },
        exporting: {
          enabled: false,
        },
        colors: [useLookup.utilities.electricity.colour],
        credits: {
          enabled: false,
        },
        title: {
          text: '',
        },
        xAxis: {
          type: 'category',
          lineColor: 'rgb(156, 163, 175)', // Color for the x-axis line
          labels: {
            style: {
              color: 'rgb(156, 163, 175)', // Color for the x-axis labels
            },
          },
        },
        yAxis: {
          title: {
            text: null,
          },
          labels: {
            formatter: function () {
              return `${useFilter.filter(this.value, 'kWh')}`
            },
            style: {
              color: 'rgb(156, 163, 175)', // Color for the x-axis labels
            },
          },
          lineColor: 'rgb(156, 163, 175)', // Color for the x-axis line
        },
        legend: {
          align: 'center',
          verticalAlign: 'bottom',
          x: 0,
          y: 0,
          floating: false,
          shadow: false,
          itemStyle: {
            color: 'rgb(156, 163, 175)', // Sets the legend text color
          },
        },
        tooltip: {
          formatter: function () {
            return `${this.point.name}<br>${this.series.name}: ${useFilter.filter(this.point.y, 'kWh')}`
          },
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: false,
            },
          },
        },
        series: [
          {
            name: `${useTranslate.t(title)}`,
            data: data,
          },
        ],
      },
      Gas: {
        chart: {
          type: 'column',
          zooming: {
            mouseWheel: {
              enabled: false,
            },
          },
        },
        exporting: {
          enabled: false,
        },
        colors: [useLookup.utilities.gas.colour],
        credits: {
          enabled: false,
        },
        title: {
          text: null,
        },
        xAxis: {
          type: 'category',
          lineColor: 'rgb(156, 163, 175)', // X-axis line color
          labels: {
            style: {
              color: 'rgb(156, 163, 175)', // X-axis labels color
            },
          },
        },
        yAxis: {
          title: {
            text: null,
          },
          lineColor: 'rgb(156, 163, 175)', // Y-axis line color
          labels: {
            formatter: function () {
              return `${useFilter.filter(this.value, 'm3')}`
            },
            style: {
              color: 'rgb(156, 163, 175)', // Y-axis labels color
            },
          },
        },
        legend: {
          align: 'center',
          verticalAlign: 'bottom',
          x: 0,
          y: 0,
          floating: false,
          shadow: false,
          itemStyle: {
            color: 'rgb(156, 163, 175)', // Legend labels color
          },
        },
        tooltip: {
          formatter: function () {
            return `${this.point.name}<br>${this.series.name}: ${useFilter.filter(this.point.y, 'm3')}`
          },
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: false,
            },
          },
        },
        series: [
          {
            name: `${useTranslate.t(title)}`,
            data: data,
          },
        ],
      },
      Water: {
        chart: {
          type: 'column',
          zooming: {
            mouseWheel: {
              enabled: false,
            },
          },
        },
        exporting: {
          enabled: false,
        },
        colors: [useLookup.utilities.water.colour],
        credits: {
          enabled: false,
        },
        title: {
          text: null,
        },
        xAxis: {
          type: 'category',
          lineColor: 'rgb(156, 163, 175)', // X-axis line color
          labels: {
            style: {
              color: 'rgb(156, 163, 175)', // X-axis labels color
            },
          },
        },
        yAxis: {
          title: {
            text: null,
          },
          lineColor: 'rgb(156, 163, 175)', // Y-axis line color
          labels: {
            formatter: function () {
              return `${useFilter.filter(this.value, 'm3')}`
            },
            style: {
              color: 'rgb(156, 163, 175)', // Y-axis labels color
            },
          },
        },
        legend: {
          align: 'center',
          verticalAlign: 'bottom',
          x: 0,
          y: 0,
          floating: false,
          shadow: false,
          itemStyle: {
            color: 'rgb(156, 163, 175)', // Legend labels color
          },
        },
        tooltip: {
          formatter: function () {
            return `${this.point.name}<br>${this.series.name}: ${useFilter.filter(this.point.y, 'm3')}`
          },
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: false,
            },
          },
        },
        series: [
          {
            name: `${useTranslate.t(title)}`,
            data: data,
          },
        ],
      },
      Heat: {
        chart: {
          type: 'column',
          zooming: {
            mouseWheel: {
              enabled: false,
            },
          },
        },
        exporting: {
          enabled: false,
        },
        colors: [useLookup.utilities.heat.colour],
        credits: {
          enabled: false,
        },
        title: {
          text: null,
        },
        xAxis: {
          type: 'category',
          lineColor: 'rgb(156, 163, 175)', // X-axis line color
          labels: {
            style: {
              color: 'rgb(156, 163, 175)', // X-axis labels color
            },
          },
        },
        yAxis: {
          title: {
            text: null,
          },
          lineColor: 'rgb(156, 163, 175)', // Y-axis line color
          labels: {
            formatter: function () {
              return `${useFilter.filter(this.value, 'GJ')}`
            },
            style: {
              color: 'rgb(156, 163, 175)', // Y-axis labels color
            },
          },
        },
        legend: {
          align: 'center',
          verticalAlign: 'bottom',
          x: 0,
          y: 0,
          floating: false,
          shadow: false,
          itemStyle: {
            color: 'rgb(156, 163, 175)', // Legend labels color
          },
        },
        tooltip: {
          formatter: function () {
            return `${this.point.name}<br>${this.series.name}: ${useFilter.filter(this.point.y, 'GJ')}`
          },
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: false,
            },
          },
        },
        series: [
          {
            name: `${useTranslate.t(title)}`,
            data: data,
          },
        ],
      },
      HeatCost: {
        chart: {
          type: 'column',
          zooming: {
            mouseWheel: {
              enabled: false,
            },
          },
        },
        exporting: {
          enabled: false,
        },
        colors: [useLookup.utilities.heatcost.colour],
        credits: {
          enabled: false,
        },
        title: {
          text: null,
        },
        xAxis: {
          type: 'category',
          lineColor: 'rgb(156, 163, 175)',
          labels: {
            style: {
              color: 'rgb(156, 163, 175)',
            },
          },
        },
        yAxis: {
          title: {
            text: null,
          },
          lineColor: 'rgb(156, 163, 175)',
          labels: {
            formatter: function () {
              return `${this.value}`
            },
            style: {
              color: 'rgb(156, 163, 175)',
            },
          },
        },
        legend: {
          align: 'center',
          verticalAlign: 'bottom',
          x: 0,
          y: 0,
          floating: false,
          shadow: false,
          itemStyle: {
            color: 'rgb(156, 163, 175)',
          },
        },
        tooltip: {
          formatter: function () {
            return `${this.point.name}<br>${this.series.name}: ${this.point.y} Eenheden`
          },
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: false,
            },
          },
        },
        series: [
          {
            name: `${useTranslate.t(title)}`,
            data: data,
          },
        ],
      },
    }

    const baseConfig = selector[type]

    if (interval === 'hour') {
      const config = { ...baseConfig }

      const units = {
        Electricity: 'kWh',
        Gas: 'm3',
        Water: 'm3',
        Heat: 'GJ',
        HeatCost: 'Eenheden',
      }

      // Convert UTC timestamps to local time for display
      config.series[0].data = data
        .map(([dateStr, value]) => {
          try {
            if (!dateStr || typeof dateStr !== 'string') {
              console.error('Invalid date string:', dateStr)
              return null
            }

            // Parse the UTC timestamp and convert to local time
            const utcDate = new Date(dateStr)
            return [utcDate.getTime(), value] // Highcharts will handle local display
          } catch (error) {
            console.error('Error parsing date:', dateStr, error)
            return null
          }
        })
        .filter((item) => item !== null)

      config.chart = {
        type: 'column',
        zoomType: 'x',
        panning: true,
        panKey: 'shift',
        zooming: {
          mouseWheel: {
            enabled: false,
          },
        },
      }

      const dutchMonths = {
        short: ['jan', 'feb', 'mrt', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
        long: [
          'januari',
          'februari',
          'maart',
          'april',
          'mei',
          'juni',
          'juli',
          'augustus',
          'september',
          'oktober',
          'november',
          'december',
        ],
      }

      config.xAxis = {
        type: 'datetime',
        ordinal: false,
        lineColor: 'rgb(156, 163, 175)',
        labels: {
          formatter: function () {
            const localDate = new Date(this.value)
            const hour = localDate.getHours()

            if (hour === 0) {
              const day = localDate.getDate()
              const month = dutchMonths.short[localDate.getMonth()]
              return `${day} ${month}<br>${hour.toString().padStart(2, '0')}:00`
            }
            return `${hour.toString().padStart(2, '0')}:00`

            // const date = new Date(this.value)
            // const hour = date.getUTCHours()

            // // For midnight, show date and time
            // if (hour === 0) {
            //   const day = date.getUTCDate()
            //   const month = dutchMonths.short[date.getUTCMonth()]
            //   return `${day} ${month}<br>${hour.toString().padStart(2, '0')}:00`
            // }
            // // For other hours, just show time
            // return `${hour.toString().padStart(2, '0')}:00`
          },
          style: {
            color: 'rgb(156, 163, 175)',
          },
        },
      }

      // Update tooltip formatter to match
      config.tooltip = {
        formatter: function () {
          // Create a local date string in Dutch format
          const localDate = new Date(this.x)
          const day = localDate.getDate().toString().padStart(2, '0')
          const month = dutchMonths.short[localDate.getMonth()]
          const year = localDate.getFullYear()
          const hours = localDate.getHours().toString().padStart(2, '0')
          const minutes = localDate.getMinutes().toString().padStart(2, '0')

          const localDateStr = `${day} ${month} ${year}, ${hours}:${minutes}`
          const unit = units[type] || ''
          const valueStr = useFilter.filter(this.y, unit)

          return `${localDateStr}<br>${this.series.name}: ${valueStr}`
        },
      }

      config.rangeSelector = {
        buttons: [
          {
            type: 'day',
            count: 1,
            text: '1D',
          },
          {
            type: 'week',
            count: 1,
            text: '1W',
          },
          {
            type: 'month',
            count: 1,
            text: '1M',
          },
          {
            type: 'month',
            count: 3,
            text: '3M',
          },
          {
            type: 'all',
            text: 'All',
          },
        ],
        selected: 3,
      }

      return config
    }

    return baseConfig
  }

  // Add new function for weekday comparison chart
  function getWeekdayComparisonOptions(data, type) {
    const { weekdayHourlyData } = processHourlyData(data)

    const weekdayColors = {
      Maandag: '#ff4d4d',
      Dinsdag: '#ffcd56',
      Woensdag: '#ff99cc',
      Donderdag: '#4dff4d',
      Vrijdag: '#ffa64d',
      Zaterdag: '#4d4dff',
      Zondag: '#800080',
    }

    const units = {
      Electricity: 'kWh',
      Gas: 'm3',
      Water: 'm3',
      Heat: 'GJ',
      HeatCost: 'Eenheden',
    }

    return {
      chart: {
        type: 'line',
        zooming: {
          mouseWheel: {
            enabled: false,
          },
        },
      },
      exporting: { enabled: false },
      credits: { enabled: false },
      title: { text: '' },
      xAxis: {
        categories: Array.from({ length: 24 }, (_, i) => `${i}:00`),
        lineColor: 'rgb(156, 163, 175)',
        labels: { style: { color: 'rgb(156, 163, 175)' } },
      },
      yAxis: {
        title: { text: null },
        labels: {
          formatter: function () {
            return useFilter.filter(this.value, units[type])
          },
          style: { color: 'rgb(156, 163, 175)' },
        },
        lineColor: 'rgb(156, 163, 175)',
      },
      tooltip: {
        formatter: function () {
          return `${this.series.name}, ${this.x}<br>${useFilter.filter(this.y, units[type])}`
        },
      },
      plotOptions: {
        line: {
          marker: { enabled: false },
        },
      },
      series: Object.entries(weekdayHourlyData).map(([day, data]) => ({
        name: day,
        color: weekdayColors[day],
        data: data.map((d) => d.value),
      })),
    }
  }

  function getScatterOptions(medium, data, regressionLines, splitDate = null) {
    const mediumConfig = {
      Electricity: {
        color: 'rgba(255, 204, 0, 0.5)',
        yAxisText: 'Verbruik (kWh)',
        tooltipFormat: '{point.date} TG: {point.x}, Verbruik: {point.y} kWh',
        unit: 'kWh',
        regressionColor: 'rgba(255, 204, 0, 0.7)',
      },
      Gas: {
        color: 'rgba(174, 204, 83, 0.5)',
        yAxisText: 'Verbruik (m3)',
        tooltipFormat: '{point.date} TG: {point.x}, Verbruik: {point.y} m3',
        unit: 'm3',
        regressionColor: 'rgba(174, 204, 83, 0.8)',
      },
      Water: {
        color: 'rgba(148, 206, 242, 0.5)',
        yAxisText: 'Verbruik (m3)',
        tooltipFormat: '{point.date} TG: {point.x}, Verbruik: {point.y} m3',
        unit: 'm3',
        regressionColor: 'rgba(148, 206, 242, 0.7)',
      },
      Heat: {
        color: 'rgba(237, 106, 90, 0.5)',
        yAxisText: 'Verbruik (GJ)',
        tooltipFormat: '{point.date} TG: {point.x}, Verbruik: {point.y} GJ',
        unit: 'GJ',
        regressionColor: 'rgba(237, 106, 90, 0.7)',
      },
      HeatCost: {
        color: 'rgba(147, 116, 138, 0.5)',
        yAxisText: 'Verbruik (Eenheden)',
        tooltipFormat: '{point.date} TG: {point.x}, Verbruik: {point.y} Eenheden',
        unit: 'Eenheden',
        regressionColor: 'rgba(147, 116, 138, 0.7)',
      },
    }

    const config = mediumConfig[medium] || mediumConfig.Electricity

    let series = []

    const comparisoncolour = 'rgba(145, 145, 145, 0.5)'

    const mediumTranslate = {
      Electricity: 'Elektriciteit',
      Gas: 'Gas',
      Water: 'Water',
      Heat: 'Warmte',
      HeatCost: 'Warmtekosten',
    }

    if (splitDate) {
      // Split plot scenario
      series = [
        {
          name: 'Verbruik voor splitsdatum',
          data: data.before.map((point) => ({
            date: point[0],
            x: point[1],
            y: point[2],
          })),
          color: comparisoncolour,
        },
        {
          name: 'Verbruik na splitsdatum',
          data: data.after.map((point) => ({
            date: point[0],
            x: point[1],
            y: point[2],
          })),
          color: config.color,
        },
        {
          name: `Regressielijn ${mediumTranslate[medium]} (voor)`,
          type: 'line',
          data: regressionLines.before,
          color: comparisoncolour,
          lineWidth: 2,
          dashStyle: 'Dash',
          marker: { enabled: false },
          states: { hover: { lineWidth: 2 } },
          enableMouseTracking: false,
        },
        {
          name: `Regressielijn ${mediumTranslate[medium]} (na)`,
          type: 'line',
          data: regressionLines.after,
          color: config.regressionColor,
          lineWidth: 2,
          dashStyle: 'Dash',
          marker: { enabled: false },
          states: { hover: { lineWidth: 2 } },
          enableMouseTracking: false,
        },
      ]
    } else {
      // Single plot scenario
      series = [
        {
          name: 'Verbruik per buitentemperatuur',
          data: data.map((point) => ({
            date: point[0],
            x: point[1],
            y: point[2],
          })),
          color: config.color,
        },
        {
          name: `Regressielijn ${mediumTranslate[medium]}`,
          type: 'line',
          data: regressionLines,
          color: config.regressionColor,
          lineWidth: 2,
          dashStyle: 'Dash',
          marker: { enabled: false },
          states: { hover: { lineWidth: 2 } },
          enableMouseTracking: false,
        },
      ]
    }

    return {
      chart: { type: 'scatter', zoomType: 'xy' },
      exporting: { enabled: false },
      credits: { enabled: false },
      title: { text: `` },
      xAxis: {
        title: { text: 'Buitentemperatuur (°C)', style: { color: 'rgb(156, 163, 175)' } },
        type: 'category',
        lineColor: 'rgb(156, 163, 175)',
        labels: { style: { color: 'rgb(156, 163, 175)' } },
      },
      yAxis: {
        title: { text: null },
        lineColor: 'rgb(156, 163, 175)',
        labels: {
          formatter: function () {
            return `${this.value} ${config.unit}`
          },
          style: { color: 'rgb(156, 163, 175)' },
        },
      },
      plotOptions: {
        scatter: {
          tooltip: {
            headerFormat: '<b>{series.name}</b><br>',
            pointFormat: config.tooltipFormat,
          },
          marker: {
            symbol: 'circle', // Set the marker symbol to 'circle' for all series
            radius: 4, // Set the marker size (adjust as needed)
          },
        },
      },
      series: series,
      legend: {
        align: 'center',
        verticalAlign: 'bottom',
        x: 0,
        y: 0,
        floating: false,
        shadow: false,
        itemStyle: { color: 'rgb(156, 163, 175)' },
      },
    }
  }

  return {
    getOptions,
    getScatterOptions,
    getWeekdayComparisonOptions,
  }
}
