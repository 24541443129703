<template>
  <teleport to="body">
    <div class="z-20">
      <!-- grey background -->
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-20"></div>
      <!-- card -->
      <div class="fixed z-20 inset-0" @keydown.esc="close()" tabindex="0" ref="focusRef">
        <div class="flex items-center justify-center min-h-screen p-4 sm:p-0">
          <!-- Removed text-center from this div -->
          <div
            :class="`relative bg-white rounded shadow-lg transform transition-all ${computedSize} ${computedHeight} flex flex-col m-4`"
          >
            <!-- Title and close button - only show if there's a title -->
            <div v-if="title" class="bg-white rounded-t px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <span class="flex justify-between">
                <div class="text-lg text-gray-500 mb-4">
                  {{ title }}
                </div>
                <div class="text-gray-400 cursor-pointer" @click="close()">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </div>
              </span>
            </div>

            <!-- If no title, just show close button in corner -->
            <div v-else class="absolute right-4 top-4 text-gray-400 cursor-pointer" @click="close()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </div>

            <!-- Main content area -->
            <div
              class="flex-grow px-4 text-left"
              :class="{
                'pt-4': !title,
                'pt-0': title,
              }"
            >
              <slot></slot>
            </div>

            <!-- Buttons area - only show if there are buttons -->
            <div
              v-if="hasButtons"
              class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse flex-shrink-0 rounded-b mt-4"
            >
              <slot name="buttons"></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { computed, onMounted, ref, useSlots } from 'vue'

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'm',
    },
    height: {
      type: String,
      default: '',
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const focusRef = ref(null)
    const slots = useSlots()

    const hasButtons = computed(() => {
      return !!slots.buttons
    })

    const sizeSelector = {
      xs: 'w-1/5',
      s: 'w-1/3',
      m: 'w-1/2',
      l: 'w-3/4',
      xl: 'w-4/5',
    }

    const heightSelector = {
      xs: 'h-[25vh] max-h-[25vh]',
      s: 'h-[50vh] max-h-[50vh]',
      m: 'h-[66vh] max-h-[66vh]',
      l: 'h-[80vh] max-h-[80vh]',
      xl: 'h-[90vh] max-h-[90vh]',
    }

    const computedSize = computed(() => {
      return sizeSelector[props.size]
    })

    const computedHeight = computed(() => {
      return heightSelector[props.height]
    })

    function close() {
      emit('close')
    }

    onMounted(() => {
      focusRef.value.focus()
    })

    return {
      sizeSelector,
      close,
      computedSize,
      computedHeight,
      focusRef,
      hasButtons,
    }
  },
}
</script>
