<!-- NodeDetailsTable.vue -->
<template>
  <div v-if="hasData" class="my-6">
    <div class="bg-white rounded-lg shadow">
      <div class="px-4 py-5">
        <h3 class="text-lg font-medium text-gray-900">Meters</h3>
      </div>
      <div class="overflow-x-auto">
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">
            <tr>
              <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                {{ $translate('Description') }}
              </th>
              <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                {{ $translate('Identification') }}
              </th>
              <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">{{ $translate('Type') }}</th>
              <th class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase">
                {{ $translate('Usage') }} ({{ unit }})
              </th>
              <th class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase">
                {{ $translate('Return') }} ({{ unit }})
              </th>
              <th class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase">
                {{ $translate('Generation') }} ({{ unit }})
              </th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <template v-for="node in uniqueNodes" :key="node.id">
              <tr class="hover:bg-gray-50 cursor-pointer" @click="toggleNode(node.id)">
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="flex items-center">
                    <featherIcon
                      class="w-4 h-4 mr-2"
                      :icon="expandedNodes[node.id] ? 'ChevronDownIcon' : 'ChevronRightIcon'"
                    />
                    <span class="font-medium">{{ node.description }}</span>
                  </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                  {{ node.provider_identifier }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                  {{ node.type.name }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-right">
                  {{ useFilter.filter(getNodeValue(node, 'Usage'), unit) }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-right">
                  {{ useFilter.filter(getNodeValue(node, 'Return'), unit) }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-right">
                  {{ useFilter.filter(getNodeValue(node, 'Generation'), unit) }}
                </td>
              </tr>
              <tr v-if="expandedNodes[node.id]">
                <td colspan="6" class="px-6 py-4 bg-gray-50">
                  <div class="pl-6">
                    <table class="min-w-full divide-y divide-gray-200">
                      <thead>
                        <tr>
                          <th class="px-3 py-2 text-left text-xs font-medium text-gray-500">
                            {{ $translate('Period') }}
                          </th>
                          <!-- <th class="px-3 py-2 text-left text-xs font-medium text-gray-500">
                            {{ $translate('Provider ID') }}
                          </th> -->
                          <th class="px-3 py-2 text-right text-xs font-medium text-gray-500">
                            {{ $translate('Usage') }}
                          </th>
                          <th class="px-3 py-2 text-right text-xs font-medium text-gray-500">
                            {{ $translate('Return') }}
                          </th>
                          <th class="px-3 py-2 text-right text-xs font-medium text-gray-500">
                            {{ $translate('Generation') }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(date, index) in getIntervalDates(node)" :key="date" class="text-sm">
                          <td class="px-3 py-2">{{ formatDate(date) }}</td>
                          <!-- <td class="px-3 py-2">{{ node.provider_identifier }}</td> -->
                          <td class="px-3 py-2 text-right">
                            {{ useFilter.filter(getIntervalValue(node, index, 'Usage'), unit) }}
                          </td>
                          <td class="px-3 py-2 text-right">
                            {{ useFilter.filter(getIntervalValue(node, index, 'Return'), unit) }}
                          </td>
                          <td class="px-3 py-2 text-right">
                            {{ useFilter.filter(getIntervalValue(node, index, 'Generation'), unit) }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
            </template>

            <!-- Totals row -->
            <tr class="bg-gray-50 font-medium border-t-2 border-gray-200">
              <td class="px-6 py-4 whitespace-nowrap" colspan="3">
                {{ $translate('Total') }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-right">
                {{ useFilter.filter(totals.Usage, unit) }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-right">
                {{ useFilter.filter(totals.Return, unit) }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-right">
                {{ useFilter.filter(totals.Generation, unit) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import filterHandler from '@/use/filterHandler'
import featherIcon from '@/components/extended/feather/featherIcon.vue'

export default {
  components: {
    featherIcon,
  },
  props: {
    nodeDetails: {
      type: Object,
      required: true,
      default: () => ({
        Usage: [],
        Return: [],
        Generation: [],
      }),
    },
    measurementData: {
      type: Object,
      required: true,
    },
    interval: {
      type: String,
      required: true,
    },
    unit: {
      type: String,
      required: true,
      default: 'kWh',
    },
  },
  setup(props) {
    const useFilter = filterHandler()
    const expandedNodes = ref({})

    const hasData = computed(() => {
      return Object.keys(props.measurementData).length > 0 && props.nodeDetails.Usage?.length > 0
    })

    const uniqueNodes = computed(() => {
      if (!hasData.value) return []

      const usageNodes = new Set(props.nodeDetails.Usage || [])
      const returnNodes = new Set(props.nodeDetails.Return || [])
      const generationNodes = new Set(props.nodeDetails.Generation || [])

      const nodes = Array.from(
        new Set([...Array.from(usageNodes), ...Array.from(returnNodes), ...Array.from(generationNodes)]),
      )

      // Sort nodes by description case-insensitively
      return nodes.sort((a, b) => {
        const descA = (a.description || '').toLowerCase()
        const descB = (b.description || '').toLowerCase()
        return descA.localeCompare(descB)
      })
    })

    const nodeDataMap = computed(() => {
      const map = {}
      for (const [nodeId, data] of Object.entries(props.measurementData)) {
        if (nodeId !== 'showPerDimension' && nodeId !== 'dimension' && nodeId !== 'dimension_unit') {
          map[nodeId] = data
        }
      }
      return map
    })

    const showPerDimension = computed(() => props.measurementData.showPerDimension || false)
    const dimension = computed(() => props.measurementData.dimension || 1)

    function adjustValue(value) {
      if (showPerDimension.value && dimension.value) {
        return value / dimension.value
      }
      return value
    }

    function getNodeValue(node, category) {
      const count_generation = node.type.count_generation
      const count_return = node.type.count_return

      const nodeData = nodeDataMap.value[node.id]
      if (!nodeData) return 0

      // Handle usage category
      if (category.toLowerCase() === 'usage') {
        return adjustValue(calculateTotal(nodeData.usage || []))
      }

      // Handle generation and return categories
      if (category.toLowerCase() === 'generation' && count_generation) {
        return adjustValue(calculateTotal(nodeData.production || []))
      }

      if (category.toLowerCase() === 'return' && count_return) {
        return adjustValue(calculateTotal(nodeData.production || []))
      }

      return 0
    }

    const totals = computed(() => {
      if (!hasData.value) return { Usage: 0, Return: 0, Generation: 0 }

      const result = {
        Usage: 0,
        Return: 0,
        Generation: 0,
      }

      for (const node of uniqueNodes.value) {
        if (props.nodeDetails.Usage.some((n) => n.id === node.id)) {
          result.Usage += getNodeValue(node, 'Usage')
        }
        if (props.nodeDetails.Return.some((n) => n.id === node.id)) {
          result.Return += getNodeValue(node, 'Return')
        }
        if (props.nodeDetails.Generation.some((n) => n.id === node.id)) {
          result.Generation += getNodeValue(node, 'Generation')
        }
      }

      return result
    })

    function getIntervalDates(node) {
      const nodeData = nodeDataMap.value[node.id]
      if (!nodeData || !nodeData.usage) return []
      return nodeData.usage.map(([date]) => date)
    }

    function getIntervalValue(node, index, category) {
      const count_generation = node.type.count_generation
      const count_return = node.type.count_return

      const nodeData = nodeDataMap.value[node.id]
      if (!nodeData) return 0

      let value = 0
      // Handle usage category directly
      if (category.toLowerCase() === 'usage') {
        value = nodeData.usage?.[index]?.[1] || 0
      }
      // Handle generation and return categories
      else if (category.toLowerCase() === 'generation' && count_generation) {
        value = nodeData.production?.[index]?.[1] || 0
      } else if (category.toLowerCase() === 'return' && count_return) {
        value = nodeData.production?.[index]?.[1] || 0
      }

      return adjustValue(value)
    }

    function calculateTotal(dataArray) {
      if (!dataArray) return 0
      return dataArray.reduce((sum, [, value]) => sum + value, 0)
    }

    function formatDate(dateString) {
      const date = new Date(dateString)
      const options = { timeZone: 'Europe/Amsterdam' }

      if (props.interval === 'year') {
        options.year = 'numeric'
        return new Intl.DateTimeFormat('nl-NL', options).format(date)
      }

      if (props.interval === 'month') {
        options.year = 'numeric'
        options.month = 'long'
        return new Intl.DateTimeFormat('nl-NL', options).format(date)
      }

      options.year = 'numeric'
      options.month = 'short'
      options.day = 'numeric'
      return new Intl.DateTimeFormat('nl-NL', options).format(date)
    }

    function toggleNode(nodeId) {
      expandedNodes.value[nodeId] = !expandedNodes.value[nodeId]
    }

    return {
      hasData,
      useFilter,
      expandedNodes,
      uniqueNodes,
      totals,
      toggleNode,
      getNodeValue,
      getIntervalDates,
      getIntervalValue,
      formatDate,
    }
  },
}
</script>
