<template>
  <span :class="pillClass">
    {{ $translate(displayText) }}
  </span>
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'PillMedium',
  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const displayText = computed(() => {
      return props.value === null ? 'Undefined' : props.value
    })

    const pillClass = computed(() => {
      const baseClasses = 'px-2 py-1 rounded-full text-xs font-medium'

      if (props.value === null) {
        return `${baseClasses} bg-gray-200/75 text-gray-800`
      }

      switch (props.value.toLowerCase()) {
        case 'electricity':
          return `${baseClasses} bg-[#ffcc00]/75 text-black`
        case 'gas':
          return `${baseClasses} bg-[#aecc53]/75 text-black`
        case 'water':
          return `${baseClasses} bg-[#94cef2]/75 text-black`
        case 'heat':
          return `${baseClasses} bg-[#ed6a5a]/75 text-white`
        case 'heatcost':
          return `${baseClasses} bg-[#93748a]/75 text-white`
        default:
          return `${baseClasses} bg-slate-50/75 text-black`
      }
    })

    return { pillClass, displayText }
  },
}
</script>
