/**
 * Formats a timestamp to Dutch date-time format
 * @param {number|string} timestamp - Unix timestamp in milliseconds
 * @returns {string} Formatted date string in Dutch format
 */
export const dutchDateParser = (timestamp) => {
  try {
    // Convert scientific notation string to number if needed
    const ts = typeof timestamp === 'string' ? parseFloat(timestamp) : timestamp

    // Create date object - assuming timestamp is in milliseconds
    const date = new Date(ts)

    // Format using specific options to match Excel format
    const formatter = new Intl.DateTimeFormat('nl-NL', {
      timeZone: 'Europe/Amsterdam',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    })

    return formatter.format(date)
  } catch (error) {
    console.error('Error parsing date:', timestamp, error)
    return timestamp // Return original value if parsing fails
  }
}

// You can add more date parsing functions here
export const dutchDateOnlyParser = (timestamp) => {
  try {
    const ts = typeof timestamp === 'string' ? parseFloat(timestamp) : timestamp
    const date = new Date(ts)
    return new Intl.DateTimeFormat('nl-NL', {
      timeZone: 'Europe/Amsterdam',
      dateStyle: 'short',
    }).format(date)
  } catch (error) {
    console.error('Error parsing date:', timestamp, error)
    return timestamp
  }
}

export const weekdayParser = (xValue) => {
  try {
    return xValue
  } catch (error) {
    console.error('Error parsing date:', xValue, error)
    return xValue
  }
}

export const dutchNumberParser = (value) => {
  if (typeof value !== 'number') return value

  return value.toLocaleString('nl-NL', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}
