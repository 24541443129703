/* eslint-disable no-unused-vars */
import { ref, computed } from 'vue'
import useValidate from '@/use/validationHandler'

export default () => {
  // data
  let fields = ref([])
  let data = ref({})
  let type = ref()
  let loading = ref(false)
  let errors = ref([])

  // handlers
  const validator = useValidate()

  function create(options) {
    fields.value = options.fields
    type.value = options.type
    if (type.value === 'edit') {
      if (options.data) {
        loadEditData(options.data)
      }
    } else {
      loadAddData()
    }
    update_errors()
  }

  function setValue(key, value) {
    data.value[key] = value
  }

  const filteredFields = computed(() => {
    return fields.value.filter((field) => {
      if (field[type.value] === false) {
        return false
      }
      return true
    })
  })

  function loadAddData() {
    filteredFields.value.forEach((field) => {
      const key = field.key
      const type = field.type

      if (type === 'list') {
        data.value[key] = []
      } else {
        data.value[key] = ''
      }
    })
  }

  function loadEditData(payload, idField = null) {
    filteredFields.value.forEach((field) => {
      if (field.type !== 'subtitle') {
        const key = field.key
        const value = payload[field.key]

        if (value || value === 0) {
          data.value[key] = value
        } else {
          if (field.type === 'list') {
            data.value[key] = []
          } else if (field.type === 'toggle') {
            if (field.default === true) {
              data.value[key] = true
            } else {
              data.value[key] = false
            }
          } else {
            data.value[key] = ''
          }
        }
      }
    })

    // also add object_id
    if (idField) {
      data.value[idField] = payload[idField]
    } else if ('object_id' in payload) {
      data.value['object_id'] = payload['object_id']
    }
  }

  function close() {
    data.value = {}
    fields.value = []
    type.value = undefined
  }

  function validate(event, type, rules, key) {
    const input = event.target.value
    const field = fields.value.find((f) => f.key === key)

    // validatorguard
    if (validator.validate(input, type)) {
      // String length check
      if (type === 'string' && field?.maxLength && input.length > field.maxLength) {
        // Optionally truncate the input to maxLength
        event.target.value = input.slice(0, field.maxLength)
        data.value[key] = event.target.value
      } else {
        // Normal handling for other cases
        if (type === 'file') {
          data.value[key] = event.target.files[0]
        } else if (type === 'check') {
          data.value[key] = event.target.checked
        } else {
          data.value[key] = input
        }
      }

      update_errors()
    } else {
      if (data.value[key] != undefined) {
        event.target.value = data.value[key]
      } else {
        event.target.value = null
      }
    }
  }

  function sync(payload) {
    data.value = payload
    update_errors()
  }

  function addListItem(key, payload) {
    if (key in data.value) {
      data.value[key].push(payload)
    } else {
      data.value[key] = [payload]
    }
  }

  function removeListItem(key, index) {
    data.value[key].splice(index, 1)
  }

  function editListItem(key, index, value) {
    data.value[key][index] = value
  }

  function getData() {
    let returnData = {}

    // parse number fields
    filteredFields.value.forEach((field) => {
      const fieldType = field.type
      const fieldKey = field.key

      if (fieldType === 'number') {
        returnData[fieldKey] = Number(data.value[fieldKey])
      } else if (fieldType === 'select' && field.select_value_type === 'number') {
        returnData[fieldKey] = Number(data.value[fieldKey])
      } else {
        if (data.value[fieldKey]) {
          returnData[fieldKey] = data.value[fieldKey]
        } else {
          if (data.value[fieldKey] === false) {
            returnData[fieldKey] = false
          } else {
            returnData[fieldKey] = null
          }
        }
      }
    })

    // check for object_id
    if ('object_id' in data.value) {
      returnData['object_id'] = data.value['object_id']
    }
    return returnData
  }

  function update_errors() {
    let error_list = []
    filteredFields.value.forEach((field) => {
      const fieldValue = data.value[field.key]
      const fieldType = field.type

      // Required field validation
      if (field.required) {
        const dependent_show_field_key = field.dependent_show_field_key
        const dependent_show_field_type = field.dependent_show_field_type
        const dependent_show_values = field.dependent_show_values

        // if field is dependent
        if (dependent_show_field_key) {
          const dependent_field_value = data.value[dependent_show_field_key]

          // check if dependancy is active
          if (dependent_show_values.includes(dependent_field_value)) {
            if (fieldValue !== undefined && fieldValue !== null && fieldValue !== '') {
              // pass
            } else {
              error_list.push({
                message: `${field.label} is niet ingevuld`,
              })
            }
          }
        } else {
          if (fieldValue !== undefined && fieldValue !== null && fieldValue !== '') {
            // pass
          } else {
            error_list.push({
              message: `${field.label} is niet ingevuld`,
            })
          }
        }
      }

      // String length validation
      if (fieldType === 'string' && field.maxLength && fieldValue) {
        if (fieldValue.length > field.maxLength) {
          error_list.push({
            message: `${field.label} mag maximaal ${field.maxLength} karakters bevatten`,
          })
        }
      }
    })
    errors.value = error_list
  }

  function removeData(key) {
    data.value[key] = ''
    update_errors()
  }

  // Add isValid computed property
  const isValid = computed(() => {
    return errors.value.length === 0
  })

  return {
    fields,
    open,
    filteredFields,
    type,
    loadAddData,
    data,
    close,
    validate,
    setValue,
    loading,
    loadEditData,
    create,
    addListItem,
    removeListItem,
    sync,
    editListItem,
    getData,
    errors,
    update_errors,
    removeData,
    isValid,
  }
}
