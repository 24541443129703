import { ref } from 'vue'
import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
import formHandler from '@/use/formHandler'
import selectValues from '@/use/selects/values'

export default () => {
  const useApi = apiHandler()
  const loadHandler = loadingHandler()
  const editForm = formHandler()

  let location = ref({})
  let nodes = ref([])
  let showEdit = ref(false)
  let showAddNodes = ref(false)
  let unassigned_nodes = ref([])
  let unassigned_nodes_selected = ref([])

  function getDetails(payload) {
    loadHandler.setLoadingState('get_details', true)
    useApi.request_api('get', 'v1', 'locations/' + payload.object_id).then((response) => {
      const data = response.data
      location.value = data
      if (response.data.nodes.length !== 0) {
        let node_ids = response.data.nodes
        useApi
          .request_api(
            'get',
            'v1',
            'nodes/?fields=description,type,place,medium,provider_identifier,object_id,geometry&' +
              node_ids.map((id) => 'ids=' + id).join('&'),
          )
          .then((response) => {
            nodes.value = response.data
            nodes.value.forEach((node) => {
              node.type_name = node.type?.name
            })
          })

        getUnassignedNodes()
        loadHandler.setLoadingState('get_details', false)
      } else {
        nodes.value = []
        getUnassignedNodes()
        loadHandler.setLoadingState('get_details', false)
      }
    })
  }

  const locationFields = ref([
    {
      label: 'Name',
      key: 'name',
      type: 'string',
      required: true,
      maxLength: 100,
    },
    {
      label: 'Dimension',
      key: 'dimension',
      type: 'number',
    },
    {
      label: 'Dimension Unit',
      key: 'dimension_unit',
      type: 'select',
      options: selectValues.dimensionUnits,
    },
  ])

  const nodeFields = ref([
    {
      label: 'Description',
      key: 'description',
      type: 'string',
      searchable: true,
    },
    {
      label: 'Identification',
      key: 'provider_identifier',
      type: 'string',
      add: false,
      edit: false,
      searchable: true,
    },
    {
      label: 'Medium',
      key: 'medium',
      type: 'string',
      component: 'pillMedium',
      searchable: true,
    },
    {
      label: 'Type',
      key: 'type_name',
      type: 'string',
      searchable: true,
    },
    {
      label: 'Place',
      key: 'place',
      type: 'string',
      searchable: true,
    },
  ])

  function openEdit() {
    editForm.create({
      type: 'edit',
      fields: locationFields.value,
      data: location.value,
    })
    showEdit.value = true
  }

  function closeEdit() {
    showEdit.value = false
  }

  function edit() {
    loadHandler.setLoadingState('edit_location', true)
    const payload = editForm.getData()

    useApi.request_api('patch', 'v1', 'locations/' + payload.object_id, payload).then(() => {
      const getDetailsPayload = {
        object_id: payload.object_id,
      }

      getDetails(getDetailsPayload)
      closeEdit()
      loadHandler.setLoadingState('edit_location', false)
    })
  }

  function openAddNodes() {
    showAddNodes.value = true
  }

  function closeAddNodes() {
    showAddNodes.value = false
  }

  function getUnassignedNodes() {
    loadHandler.setLoadingState('get_unassigned_nodes', true)
    useApi.request_api('get', 'v1', `nodes/unassigned/locations/${location.value.object_id}`).then((response) => {
      const data = response.data
      if (data.length === 0) {
        unassigned_nodes.value = []
        loadHandler.setLoadingState('get_unassigned_nodes', false)
        return
      }
      let node_ids_parameters = response.data.map((node) => 'ids=' + node).join('&')
      useApi
        .request_api(
          'get',
          'v1',
          'nodes/?fields=object_id,description,medium,provider_identifier,place,type&' + node_ids_parameters,
        )
        .then((response) => {
          unassigned_nodes.value = response.data
          unassigned_nodes.value.forEach((node) => {
            node.type_name = node.type?.name
          })
          loadHandler.setLoadingState('get_unassigned_nodes', false)
        })
    })
  }

  function assignNodes() {
    const payload = {
      ids: Object.keys(unassigned_nodes_selected.value),
    }

    loadHandler.setLoadingState('assign_nodes', true)
    useApi.request_api('patch', 'v1', 'locations/' + location.value.object_id + '/nodes/add', payload).then(() => {
      closeAddNodes()
      loadHandler.setLoadingState('assign_nodes', false)

      const getDetailsPayload = {
        object_id: location.value.object_id,
      }

      getDetails(getDetailsPayload)
    })
  }

  function setSelectedUnassignedNodes(selection) {
    unassigned_nodes_selected.value = selection
  }

  function unAssignNode(nodeId) {
    const payload = { ids: [nodeId] }

    loadHandler.setLoadingState('unassign_node', true)
    useApi.request_api('patch', 'v1', 'locations/' + location.value.object_id + '/nodes/remove', payload).then(() => {
      loadHandler.setLoadingState('unassign_node', false)
      const getDetailsPayload = {
        object_id: location.value.object_id,
      }

      getDetails(getDetailsPayload)
    })
  }

  return {
    location,
    loadHandler,
    locationFields,
    nodeFields,
    showEdit,
    getDetails,
    editForm,
    openEdit,
    closeEdit,
    edit,
    openAddNodes,
    closeAddNodes,
    showAddNodes,
    getUnassignedNodes,
    assignNodes,
    setSelectedUnassignedNodes,
    unAssignNode,
    unassigned_nodes,
    unassigned_nodes_selected,
    nodes,
  }
}
