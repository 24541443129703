<template>
  <div>
    <div class="relative">
      <!-- button -->
      <base-button :variant="variant" :size="size" :loading="loading" :disabled="disabled" @action="handler.toggle()">
        <div class="flex flex-row items-center">
          <span v-if="!icon" class="text-xs">
            <!-- removed my-auto, added items-center to parent -->
            <span v-if="translate">{{ $translate(displayText) }}</span>
            <span v-else>{{ displayText }}</span>
          </span>
          <span v-else><featherIcon class="w-4" :icon="icon" /></span>
          <span v-if="!icon" class="ml-2"><featherIcon class="w-4" icon="ChevronDownIcon" /></span>
          <!-- removed mt-auto -->
        </div>
      </base-button>

      <!-- value list -->
      <div
        ref="dropdown_ref"
        v-if="handler.open.value"
        :class="{ 'right-aligned': alignRight, 'left-aligned': !alignRight }"
        class="rounded shadow-md bg-white absolute mt-2 z-20 py-1"
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'
export default {
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
    size: {
      type: String,
      default: 'normal',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    translate: {
      type: Boolean,
      default: true,
    },
    displayText: {
      type: String,
      default: 'normal',
    },
    icon: {
      type: String,
      default: '',
    },
    handler: {
      type: Object,
      default: () => {},
    },
    alignRight: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const dropdown_ref = ref(null)

    onMounted(() => window.addEventListener('click', clicker))
    onUnmounted(() => window.removeEventListener('click', clicker))

    function clicker(event) {
      if (props.handler.open.value) {
        if (!dropdown_ref.value.contains(event.target)) {
          props.handler.toggle()
        }
      }
    }

    return {
      dropdown_ref,
    }
  },
}
</script>

<style>
.right-aligned {
  right: 0;
}

.left-aligned {
  left: 0;
}
</style>
