<!-- MediaDetailsTable.vue -->
<template>
  <div v-if="hasData" class="my-6">
    <div class="bg-white rounded-lg shadow">
      <div class="px-4 py-5">
        <h3 class="text-lg font-medium text-gray-900">Meters</h3>
      </div>
      <div class="overflow-x-auto">
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">
            <tr>
              <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                {{ $translate('Description') }}
              </th>
              <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                {{ $translate('Identification') }}
              </th>
              <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">{{ $translate('Type') }}</th>
              <th class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase">
                {{ $translate('Usage') }} ({{ displayUnit }})
              </th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <template v-for="node in uniqueNodes" :key="node.id">
              <tr class="hover:bg-gray-50 cursor-pointer" @click="toggleNode(node.id)">
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="flex items-center">
                    <featherIcon
                      class="w-4 h-4 mr-2"
                      :icon="expandedNodes[node.id] ? 'ChevronDownIcon' : 'ChevronRightIcon'"
                    />
                    <span class="font-medium">{{ node.description }}</span>
                  </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                  {{ node.provider_identifier }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                  {{ node.type.name }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-right">
                  {{ useFilter.filter(getNodeValue(node), displayUnit) }}
                </td>
              </tr>
              <tr v-if="expandedNodes[node.id]">
                <td colspan="4" class="px-6 py-4 bg-gray-50">
                  <div class="pl-6">
                    <table class="min-w-full divide-y divide-gray-200">
                      <thead>
                        <tr>
                          <th class="px-3 py-2 text-left text-xs font-medium text-gray-500">
                            {{ $translate('Period') }}
                          </th>
                          <th class="px-3 py-2 text-right text-xs font-medium text-gray-500">
                            {{ $translate('Usage') }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(date, index) in getIntervalDates(node)" :key="date" class="text-sm">
                          <td class="px-3 py-2">{{ formatDate(date) }}</td>
                          <td class="px-3 py-2 text-right">
                            {{ useFilter.filter(getIntervalValue(node, index), displayUnit) }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
            </template>

            <!-- Totals row -->
            <tr class="bg-gray-50 font-medium border-t-2 border-gray-200">
              <td class="px-6 py-4 whitespace-nowrap" colspan="3">
                {{ $translate('Total') }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-right">
                {{ useFilter.filter(total, displayUnit) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import filterHandler from '@/use/filterHandler'
import featherIcon from '@/components/extended/feather/featherIcon.vue'

export default {
  components: {
    featherIcon,
  },
  props: {
    nodeDetails: {
      type: Object,
      required: true,
      default: () => ({
        Usage: [],
      }),
    },
    measurementData: {
      type: Object,
      required: true,
    },
    interval: {
      type: String,
      required: true,
    },
    unit: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const useFilter = filterHandler()
    const expandedNodes = ref({})

    const hasData = computed(() => {
      return Object.keys(props.measurementData).length > 0 && props.nodeDetails.Usage?.length > 0
    })

    const uniqueNodes = computed(() => {
      if (!hasData.value) return []
      // Sort nodes by description case-insensitively
      return [...props.nodeDetails.Usage].sort((a, b) => {
        const descA = (a.description || '').toLowerCase()
        const descB = (b.description || '').toLowerCase()
        return descA.localeCompare(descB)
      })
    })

    const nodeDataMap = computed(() => {
      const map = {}
      const excludeKeys = ['showPerDimension', 'dimension', 'dimension_unit']
      for (const [key, value] of Object.entries(props.measurementData)) {
        if (!excludeKeys.includes(key)) {
          map[key] = value
        }
      }
      return map
    })

    const showPerDimension = computed(() => {
      return props.measurementData.showPerDimension || false
    })

    const dimensionValue = computed(() => {
      return props.measurementData.dimension || 1
    })

    const dimensionUnit = computed(() => {
      return props.measurementData.dimension_unit || ''
    })

    const displayUnit = computed(() => {
      if (showPerDimension.value && dimensionUnit.value) {
        return `${props.unit}`
      }
      return props.unit
    })

    function adjustValue(value) {
      if (showPerDimension.value && dimensionValue.value) {
        return value / dimensionValue.value
      }
      return value
    }

    function getNodeValue(node) {
      const nodeData = nodeDataMap.value[node.id]
      if (!nodeData?.usage) return 0
      const value = calculateTotal(nodeData.usage)
      return adjustValue(value)
    }

    const total = computed(() => {
      if (!hasData.value) return 0
      const totalValue = uniqueNodes.value.reduce((sum, node) => {
        return sum + getNodeValue(node)
      }, 0)
      return totalValue
    })

    function getIntervalDates(node) {
      const nodeData = nodeDataMap.value[node.id]
      if (!nodeData?.usage) return []
      return nodeData.usage.map(([date]) => date)
    }

    function getIntervalValue(node, index) {
      const nodeData = nodeDataMap.value[node.id]
      if (!nodeData?.usage) return 0
      const value = nodeData.usage[index]?.[1] || 0
      return adjustValue(value)
    }

    function calculateTotal(dataArray) {
      if (!dataArray) return 0
      return dataArray.reduce((sum, [, value]) => sum + value, 0)
    }

    function formatDate(dateString) {
      const date = new Date(dateString)
      const options = { timeZone: 'Europe/Amsterdam' }

      if (props.interval === 'year') {
        options.year = 'numeric'
        return new Intl.DateTimeFormat('nl-NL', options).format(date)
      }

      if (props.interval === 'month') {
        options.year = 'numeric'
        options.month = 'long'
        return new Intl.DateTimeFormat('nl-NL', options).format(date)
      }

      options.year = 'numeric'
      options.month = 'short'
      options.day = 'numeric'
      return new Intl.DateTimeFormat('nl-NL', options).format(date)
    }

    function toggleNode(nodeId) {
      expandedNodes.value[nodeId] = !expandedNodes.value[nodeId]
    }

    return {
      hasData,
      useFilter,
      expandedNodes,
      uniqueNodes,
      total,
      toggleNode,
      getNodeValue,
      getIntervalDates,
      getIntervalValue,
      formatDate,
      displayUnit,
    }
  },
}
</script>
