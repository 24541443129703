export default () => {
  const state = {
    settings: {
      colourMode: 'light',
      pageTitle: 'text-xl my-8 text-slate-500',
      colours: {
        light: {
          background: {
            background: 'bg-slate-50',
            primary: 'bg-sky-700',
            secondary: 'bg-[#93B7BE]',
            success: 'bg-green-700',
            danger: 'bg-red-600',
            transparent: 'bg-transparent',
            surface: 'bg-white',
            disabled: 'bg-gray-300',
            neutral: 'bg-slate-50',
            electricity: 'bg-[#ffcc00]',
            gas: 'bg-[#aecc53]',
            water: 'bg-[#94cef2]',
            heat: 'bg-[#ed6a5a]',
            heatcost: 'bg-[#93748a]',
            true: 'bg-green-700',
            false: 'bg-red-600',
          },
          text: {
            primary: 'text-white',
            success: 'text-white',
            surface: 'text-black',
            danger: 'text-white',
            disabled: 'text-gray-600',
            neutral: 'text-gray-600',
          },
          hover: {
            neutral: 'hover:bg-white',
            disabled: '',
          },
        },
        dark: {
          background: {
            background: 'bg-slate-50',
            primary: 'bg-sky-700',
            secondary: 'bg-[#93B7BE]',
            success: 'bg-green-700',
            danger: 'bg-red-600',
            transparent: 'bg-transparent',
            surface: 'bg-white',
            disabled: 'bg-gray-300',
            neutral: 'bg-slate-50',
            electricity: 'bg-[#ffcc00]',
            gas: 'bg-[#aecc53]',
            water: 'bg-[#94cef2]',
            heat: 'bg-[#ed6a5a]',
            heatcost: 'bg-[#93748a]',
          },
          text: {
            primary: 'text-white',
            surface: 'text-black',
            success: 'text-white',
            danger: 'text-white',
            disabled: 'text-gray-600',
            neutral: 'text-gray-600',
          },
          hover: {
            neutral: 'hover:bg-white',
            disabled: '',
          },
        },
      },
    },

    navBar: {
      title: 'Datamanager',
      items: [
        // {
        //   title: '',
        //   action: {
        //     type: 'storeMutation',
        //     target: 'panoramaMap/toggleModal',
        //   },
        // },
        {
          title: 'Uitloggen',
          action: {
            type: 'logOut',
          },
        },
      ],
    },
  }

  function getStyle(name) {
    return state.settings.colours[state.settings.colourMode][name]
  }

  function getBackgroundColor(name) {
    return state.settings.colours[state.settings.colourMode].background[name]
  }

  function getTextColor(name) {
    return state.settings.colours[state.settings.colourMode].text[name]
  }

  function getHover(name) {
    return state.settings.colours[state.settings.colourMode].hover[name]
  }

  return {
    getStyle,
    getBackgroundColor,
    getTextColor,
    getHover,
  }
}
