<template>
  <baseModal v-if="handler.show.value" @close="handler.cancel()" :title="handler.title.value" size="xs">
    <span class="text-gray-400">
      {{ $translate(handler.message.value) }}
    </span>
    <template v-slot:buttons>
      <baseButton @click="handler.cancel()">{{ $translate('No') }}</baseButton>
      <baseButton @click="handler.confirm()" class="mr-2">{{ $translate('Yes') }}</baseButton>
    </template>
  </baseModal>
</template>

<script>
export default {
  props: {
    handler: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['close'],
}
</script>
