import { ref } from 'vue'

export default () => {
  let tabs = ref([])
  let selected = ref('')

  // create
  function create(options, startid) {
    selected.value = startid

    // Filter only active tabs
    const activeTabs = options.tabs.filter((tab) => tab.active)

    // Clear existing tabs and add new ones
    tabs.value = activeTabs

    // If selected tab is not active anymore, select first active tab
    if (!activeTabs.find((tab) => tab.id === selected.value) && activeTabs.length > 0) {
      selected.value = activeTabs[0].id
    }
  }

  function onSelect(tabId) {
    selected.value = tabId
  }

  return {
    create,
    tabs,
    selected,
    onSelect,
  }
}
