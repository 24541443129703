import { computed, ref } from 'vue'
import loadingHandler from '@/use/loadingHandler'
import L from 'leaflet'
import markerHandler from '@/views/components/mapIcons/elecricity'

export default () => {
  const loadHandler = loadingHandler()
  const editState = ref('neutral_no_location')
  const originalState = ref('')
  const geometry = ref({})
  const useMarkers = markerHandler()
  const settings = ref({})
  const searchMarker = ref({})

  function load(geometry, markerType) {
    geometry.value = geometry
    settings.value['markerType'] = markerType
  }

  const buttonText = computed(() => {
    if (editState.value === 'editing') {
      return 'Save'
    }
    if (editState.value === 'neutral_location') {
      return 'Edit geometry'
    }
    if (editState.value === 'neutral_no_location') {
      return 'Add geometry'
    }
    if (editState.value === 'success') {
      return 'Success'
    }
    if (editState.value === 'error') {
      return 'Error'
    }
    return ''
  })

  const buttonColour = computed(() => {
    if (editState.value === 'editing') {
      return 'warning'
    }
    if (editState.value === 'neutral_location') {
      return 'primary'
    }
    if (editState.value === 'neutral_no_location') {
      return 'primary'
    }
    if (editState.value === 'success') {
      return 'success'
    }
    if (editState.value === 'error') {
      return 'danger'
    }
    return 'primary'
  })

  function startEdit() {
    setState('editing')
  }

  function editGeometry() {
    loadHandler.setLoadingState('edit_geometry', true)
  }

  function setState(state) {
    editState.value = state
  }

  function setStateOriginal(state) {
    originalState.value = state
  }

  function setGeometry(value) {
    if (editState.value === 'editing') {
      geometry.value = value
    }
  }

  function getMarker(type) {
    const selector = {
      Electricity: useMarkers.electricityMarker,
      Gas: useMarkers.gasMarker,
      Water: useMarkers.waterMarker,
      Heat: useMarkers.heatMarker,
      HeatCost: useMarkers.heatCostMarker,
    }

    return selector[type]
  }

  function updateMarker(marker, lat, lng) {
    var newLatLng = new L.LatLng(lat, lng)
    marker.setLatLng(newLatLng)
  }

  function panMap(map, lat, lng, zoom = null) {
    if (zoom !== null) {
      map.setView([lat, lng], zoom)
    } else {
      map.setView([lat, lng])
    }
  }

  function addSearchMarker(map, lat, lng) {
    map.removeLayer(searchMarker.value)
    searchMarker.value = L.marker([lat, lng], { icon: useMarkers.targetMarker })
    searchMarker.value.addTo(map)
  }

  function removeSearchMarker(map) {
    map.removeLayer(searchMarker.value)
  }

  function renderMap(target, name, clickable, settings) {
    const map = L.map(target, { attributionControl: false })
    const nodes = settings.value.nodes
    var markers = new L.FeatureGroup()

    nodes.forEach((node) => {
      if (node.geometry) {
        const geometry = node.geometry
        const marker = L.marker([geometry.lat, geometry.lng], { icon: getMarker(settings.value.markerType) })
        marker.addTo(markers)
      }
    })

    map.addLayer(markers)

    L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
      maxZoom: 19,
      id: name,
    }).addTo(map)

    setTimeout(function () {
      map.invalidateSize()

      if (markers.getLayers().length > 0) {
        map.fitBounds(markers.getBounds().pad(0.1))
      } else {
        map.setView([52, 6], 7)
      }
    }, 100)

    return {
      map,
      markers,
    }
  }

  return {
    buttonText,
    buttonColour,
    startEdit,
    loadHandler,
    editGeometry,
    setState,
    setStateOriginal,
    setGeometry,
    editState,
    originalState,
    renderMap,
    load,
    geometry,
    updateMarker,
    panMap,
    addSearchMarker,
    removeSearchMarker,
  }
}
