<template>
  <div class="w-full">
    <!-- Dashboard Cards -->
    <div class="grid grid-cols-1 md:grid-cols-4 gap-4 my-6" v-if="hasGasData">
      <!-- Supply Card -->
      <div
        class="bg-white rounded-lg shadow p-4 cursor-pointer transition-colors duration-200 hover:shadow-lg hover:bg-gray-50"
        v-if="hasUsageData"
        @click="activeSection = 'supply'"
      >
        <div class="text-gray-500 text-sm mb-1">{{ $translate('Supply') }}</div>
        <div class="text-2xl font-bold">
          {{ useFilter.filter(viewHandlerAmounts.sumSummary(viewHandlerAmounts.dataGas.value.Usage), displayUnit) }}
        </div>
        <div class="text-sm text-gray-400">{{ usageNodesCount }} {{ $translate('Nodes') }}</div>
      </div>
    </div>

    <!-- Content Section -->
    <!-- Supply Section -->
    <div v-if="activeSection === 'supply' && hasUsageData" class="bg-white rounded-lg shadow p-6 mb-6">
      <div class="flex justify-between items-center mb-4">
        <h3 class="text-lg font-semibold text-gray-700">{{ $translate('Supply') }}</h3>
      </div>

      <cardChart
        v-if="viewHandlerAmounts.chart_options_usage_gas?.value"
        :options="getAdjustedChartOptions(viewHandlerAmounts.chart_options_usage_gas.value)"
        :scatter="viewHandlerAmounts.filterForm.data.value.interval === 'day'"
        :scatterOptions="getAdjustedChartOptions(viewHandlerAmounts.chart_options_scatter_gas.value)"
        :exportUnit="displayUnit"
        exportTitle="Simpleweg - Gas - Levering"
      />

      <div
        class="flex justify-between items-center mb-4 mt-6"
        v-if="
          viewHandlerAmounts.filterForm.data.value.interval === 'hour' &&
          viewHandlerAmounts.chart_options_usage_gas_weekday?.value
        "
      >
        <h3 class="text-lg font-semibold text-gray-700">Dagprofiel</h3>
      </div>

      <cardChart
        v-if="
          viewHandlerAmounts.filterForm.data.value.interval === 'hour' &&
          viewHandlerAmounts.chart_options_usage_gas_weekday?.value
        "
        type="stockChart"
        :options="getAdjustedChartOptions(viewHandlerAmounts.chart_options_usage_gas_weekday.value)"
        :scatter="false"
        :scatterOptions="{}"
        :exportUnit="displayUnit"
        exportTitle="Simpleweg - Gas - Levering - Dagprofiel"
        :show-info="true"
        :info-component="WeekdayProfileInfo"
      />
    </div>
  </div>
</template>

<script>
import filterHandler from '@/use/filterHandler'
import cardChart from '@/views/components/cardChart.vue'
import { computed, ref } from 'vue'
import { dutchDateParser } from './parsers'
import WeekdayProfileInfo from '@/views/components/info/WeekdayProfileInfo.vue'

export default {
  props: {
    type: {
      type: String,
      default: '',
    },
    viewHandlerAmounts: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const useFilter = filterHandler()
    const activeSection = ref('supply') // Default to supply view

    const displayUnit = computed(() => {
      const baseUnit = 'm3'
      if (
        props.viewHandlerAmounts.showPerDimension?.value &&
        props.viewHandlerAmounts.filterForm.data.value.dimension_unit
      ) {
        return `${baseUnit}/${props.viewHandlerAmounts.filterForm.data.value.dimension_unit}`
      }
      return baseUnit
    })

    const usageNodes = computed(() => props.viewHandlerAmounts.categorizedNodes.value?.Gas?.Usage || [])
    const usageNodesCount = computed(() => usageNodes.value?.length)

    const hasGasData = computed(() => {
      return !!props.viewHandlerAmounts.dataGas.value
    })

    const hasUsageData = computed(() => {
      return !!props.viewHandlerAmounts.dataGas.value?.Usage
    })

    // Function to adjust chart data for per dimension values
    function getAdjustedChartOptions(options) {
      if (
        !options ||
        !props.viewHandlerAmounts.showPerDimension?.value ||
        !props.viewHandlerAmounts.filterForm.data.value.dimension
      ) {
        return options
      }

      const dimension = props.viewHandlerAmounts.filterForm.data.value.dimension
      const newOptions = JSON.parse(JSON.stringify(options)) // Deep clone to avoid modifying original

      // Adjust series data
      if (newOptions.series) {
        newOptions.series = newOptions.series.map((series) => ({
          ...series,
          data: series.data.map((point) => {
            if (Array.isArray(point)) {
              // For array format [date, value]
              return [point[0], point[1] / dimension]
            } else if (typeof point === 'object' && point !== null) {
              // For object format with x,y coordinates
              return {
                ...point,
                y: point.y / dimension,
                value: point.value ? point.value / dimension : undefined,
              }
            }
            // For simple number values
            return point / dimension
          }),
        }))
      }

      // Update yAxis labels to show per dimension unit
      if (newOptions.yAxis) {
        newOptions.yAxis = {
          ...newOptions.yAxis,
          labels: {
            ...newOptions.yAxis.labels,
            formatter: function () {
              return useFilter.filter(this.value, displayUnit.value)
            },
          },
        }
      }

      // Update tooltip to show per dimension values
      if (newOptions.tooltip) {
        newOptions.tooltip = {
          ...newOptions.tooltip,
          formatter: function () {
            const context = this
            const value = context.y
            const date = context.point.name || context.x
            const seriesName = context.series.name

            // For scatter plots
            if (context.point.date) {
              return `${context.point.date}<br>${seriesName}: ${useFilter.filter(value, displayUnit.value)}`
            }

            // For regular charts
            if (typeof date === 'string') {
              return `${date}<br>${seriesName}: ${useFilter.filter(value, displayUnit.value)}`
            }

            // For timestamp dates
            const formattedDate = new Date(date).toLocaleDateString('nl-NL', {
              year: 'numeric',
              month: 'short',
            })
            return `${formattedDate}<br>${seriesName}: ${useFilter.filter(value, displayUnit.value)}`
          },
        }
      }

      // Update plotOptions for column charts
      if (newOptions.plotOptions?.column) {
        newOptions.plotOptions.column = {
          ...newOptions.plotOptions.column,
          dataLabels: {
            ...newOptions.plotOptions.column.dataLabels,
            formatter: function () {
              return useFilter.filter(this.y, displayUnit.value)
            },
          },
        }
      }

      return newOptions
    }

    return {
      useFilter,
      dutchDateParser,
      WeekdayProfileInfo,
      usageNodesCount,
      hasGasData,
      hasUsageData,
      activeSection,
      displayUnit,
      getAdjustedChartOptions,
    }
  },
  components: {
    cardChart,
  },
}
</script>
