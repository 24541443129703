<!-- Previous template content remains the same until the cardChart components -->

<template>
  <div class="w-full">
    <!-- Dashboard Cards -->
    <div class="grid grid-cols-1 md:grid-cols-4 gap-4 my-6" v-if="hasElectricityData">
      <!-- Supply Card -->
      <div
        class="bg-white rounded-lg shadow p-4 cursor-pointer transition-colors duration-200 hover:shadow-lg hover:bg-gray-50"
        v-if="hasUsageData"
        @click="activeSection = 'supply'"
      >
        <div class="text-gray-500 text-sm mb-1">{{ $translate('Supply') }}</div>
        <div class="text-2xl font-bold">
          {{
            useFilter.filter(viewHandlerAmounts.sumSummary(viewHandlerAmounts.dataElectricity.value.Usage), displayUnit)
          }}
        </div>
        <div class="text-sm text-gray-400">{{ usageNodesCount }} {{ $translate('Nodes') }}</div>
      </div>

      <!-- Return Card -->
      <div
        class="bg-white rounded-lg shadow p-4 cursor-pointer transition-colors duration-200 hover:shadow-lg hover:bg-gray-50"
        v-if="hasReturnData"
        @click="activeSection = 'return'"
      >
        <div class="text-gray-500 text-sm mb-1">{{ $translate('Return') }}</div>
        <div class="text-2xl font-bold">
          {{
            useFilter.filter(
              viewHandlerAmounts.sumSummary(viewHandlerAmounts.dataElectricity.value.Return),
              displayUnit,
            )
          }}
        </div>
        <div class="text-sm text-gray-400">{{ returnNodesCount }} {{ $translate('Nodes') }}</div>
      </div>

      <!-- Generation Card -->
      <div
        class="bg-white rounded-lg shadow p-4 cursor-pointer transition-colors duration-200 hover:shadow-lg hover:bg-gray-50"
        v-if="hasGenerationData"
        @click="activeSection = 'generation'"
      >
        <div class="text-gray-500 text-sm mb-1">{{ $translate('Generation') }}</div>
        <div class="text-2xl font-bold">
          {{
            useFilter.filter(
              viewHandlerAmounts.sumSummary(viewHandlerAmounts.dataElectricity.value.Generation),
              displayUnit,
            )
          }}
        </div>
        <div class="text-sm text-gray-400">{{ generationNodesCount }} {{ $translate('Nodes') }}</div>
      </div>

      <!-- Combined Card -->
      <div
        class="bg-white rounded-lg shadow p-4 cursor-pointer transition-colors duration-200 hover:shadow-lg hover:bg-gray-50"
        v-if="hasUsageData || hasReturnData || hasGenerationData"
        @click="activeSection = 'combined'"
      >
        <div class="text-gray-500 text-sm mb-1">{{ $translate('Combined') }}</div>
        <div class="flex justify-between">
          <div class="text-2xl font-bold">
            {{ useFilter.filter(calculateSaldo, displayUnit) }}
          </div>
          <div class="text-2xl font-bold">
            {{ useFilter.filter(calculateEigenGebruik, displayUnit) }}
          </div>
        </div>
        <div class="flex justify-between">
          <div class="text-sm text-gray-400">Saldo</div>
          <div class="text-sm text-gray-400">Eigen gebruik</div>
        </div>
      </div>
    </div>

    <!-- Content Sections -->
    <!-- Supply Section -->
    <div v-if="activeSection === 'supply' && hasUsageData" class="bg-white rounded-lg shadow p-6 mb-6">
      <div class="flex justify-between items-center mb-4">
        <h3 class="text-lg font-semibold text-gray-700">{{ $translate('Supply') }}</h3>
      </div>

      <cardChart
        v-if="viewHandlerAmounts.chart_options_usage_electricity?.value"
        :options="getAdjustedChartOptions(viewHandlerAmounts.chart_options_usage_electricity.value)"
        :scatter="viewHandlerAmounts.filterForm.data.value.interval === 'day'"
        :scatterOptions="getAdjustedChartOptions(viewHandlerAmounts.chart_options_scatter_electricity.value)"
        :exportUnit="displayUnit"
        exportTitle="Simpleweg - Elektriciteit - Levering"
      />

      <div
        class="flex justify-between items-center mb-4 mt-6"
        v-if="
          viewHandlerAmounts.filterForm.data.value.interval === 'hour' &&
          viewHandlerAmounts.chart_options_usage_electricity_weekday?.value
        "
      >
        <h3 class="text-lg font-semibold text-gray-700">Dagprofiel</h3>
      </div>

      <cardChart
        v-if="
          viewHandlerAmounts.filterForm.data.value.interval === 'hour' &&
          viewHandlerAmounts.chart_options_usage_electricity_weekday?.value
        "
        type="stockChart"
        :options="getAdjustedChartOptions(viewHandlerAmounts.chart_options_usage_electricity_weekday.value)"
        :scatter="false"
        :scatterOptions="{}"
        :exportUnit="displayUnit"
        exportTitle="Simpleweg - Elektriciteit - Levering - Dagprofiel"
        :show-info="true"
        :info-component="WeekdayProfileInfo"
      />
    </div>

    <!-- Return Section -->
    <div v-if="activeSection === 'return' && hasReturnData" class="bg-white rounded-lg shadow p-6 mb-6">
      <div class="flex justify-between items-center mb-4">
        <h3 class="text-lg font-semibold text-gray-700">{{ $translate('Return') }}</h3>
      </div>

      <cardChart
        v-if="viewHandlerAmounts.chart_options_return_electricity?.value"
        :options="getAdjustedChartOptions(viewHandlerAmounts.chart_options_return_electricity.value)"
        :scatter="viewHandlerAmounts.filterForm.data.value.interval === 'day'"
        :scatterOptions="getAdjustedChartOptions(viewHandlerAmounts.chart_options_scatter_electricity.value)"
        :exportUnit="displayUnit"
        exportTitle="Simpleweg - Elektriciteit - Teruglevering"
      />

      <div
        class="flex justify-between items-center mb-4 mt-6"
        v-if="
          viewHandlerAmounts.filterForm.data.value.interval === 'hour' &&
          viewHandlerAmounts.chart_options_return_electricity_weekday?.value
        "
      >
        <h3 class="text-lg font-semibold text-gray-700">Dagprofiel</h3>
      </div>

      <cardChart
        v-if="
          viewHandlerAmounts.filterForm.data.value.interval === 'hour' &&
          viewHandlerAmounts.chart_options_return_electricity_weekday?.value
        "
        type="stockChart"
        :options="getAdjustedChartOptions(viewHandlerAmounts.chart_options_return_electricity_weekday.value)"
        :scatter="false"
        :scatterOptions="{}"
        :exportUnit="displayUnit"
        exportTitle="Simpleweg - Elektriciteit - Teruglevering - Dagprofiel"
        :show-info="true"
        :info-component="WeekdayProfileInfo"
      />
    </div>

    <!-- Generation Section -->
    <div v-if="activeSection === 'generation' && hasGenerationData" class="bg-white rounded-lg shadow p-6 mb-6">
      <div class="flex justify-between items-center mb-4">
        <h3 class="text-lg font-semibold text-gray-700">{{ $translate('Generation') }}</h3>
      </div>

      <cardChart
        v-if="viewHandlerAmounts.chart_options_generation_electricity?.value"
        :options="getAdjustedChartOptions(viewHandlerAmounts.chart_options_generation_electricity.value)"
        :scatter="viewHandlerAmounts.filterForm.data.value.interval === 'day'"
        :scatterOptions="getAdjustedChartOptions(viewHandlerAmounts.chart_options_scatter_electricity.value)"
        :exportUnit="displayUnit"
        exportTitle="Simpleweg - Elektriciteit - Opwekking"
      />

      <div
        class="flex justify-between items-center mb-4 mt-6"
        v-if="
          viewHandlerAmounts.filterForm.data.value.interval === 'hour' &&
          viewHandlerAmounts.chart_options_generation_electricity_weekday?.value
        "
      >
        <h3 class="text-lg font-semibold text-gray-700">Dagprofiel</h3>
      </div>

      <cardChart
        v-if="
          viewHandlerAmounts.filterForm.data.value.interval === 'hour' &&
          viewHandlerAmounts.chart_options_generation_electricity_weekday?.value
        "
        type="stockChart"
        :options="getAdjustedChartOptions(viewHandlerAmounts.chart_options_generation_electricity_weekday.value)"
        :scatter="false"
        :scatterOptions="{}"
        :exportUnit="displayUnit"
        exportTitle="Simpleweg - Elektriciteit - Opwekking - Dagprofiel"
        :show-info="true"
        :info-component="WeekdayProfileInfo"
      />
    </div>

    <!-- Combined Section -->
    <div v-if="activeSection === 'combined'" class="bg-white rounded-lg shadow p-6 mb-6">
      <div class="flex justify-between items-center mb-4">
        <h3 class="text-lg font-semibold text-gray-700">{{ $translate('Combined') }}</h3>
      </div>

      <cardChart
        v-if="combinedChartOptions"
        :options="getAdjustedChartOptions(combinedChartOptions)"
        :scatter="viewHandlerAmounts.filterForm.data.value.interval === 'day'"
        :scatterOptions="getAdjustedChartOptions(viewHandlerAmounts.chart_options_scatter_electricity.value)"
        :exportUnit="displayUnit"
        exportTitle="Simpleweg - Elektriciteit - Gecombineerd"
      />
    </div>
  </div>
</template>

<script>
import filterHandler from '@/use/filterHandler'
import cardChart from '@/views/components/cardChart.vue'
import { computed, ref } from 'vue'
import { dutchDateParser, weekdayParser, dutchNumberParser } from './parsers'
import WeekdayProfileInfo from '@/views/components/info/WeekdayProfileInfo.vue'

export default {
  props: {
    type: {
      type: String,
      default: '',
    },
    viewHandlerAmounts: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const useFilter = filterHandler()
    const activeSection = ref('supply') // Default to supply view

    const displayUnit = computed(() => {
      const baseUnit = 'kWh'
      if (
        props.viewHandlerAmounts.showPerDimension?.value &&
        props.viewHandlerAmounts.filterForm.data.value.dimension_unit
      ) {
        return `${baseUnit}/${props.viewHandlerAmounts.filterForm.data.value.dimension_unit}`
      }
      return baseUnit
    })

    const usageNodes = computed(() => props.viewHandlerAmounts.categorizedNodes.value?.Electricity?.Usage || [])
    const returnNodes = computed(() => props.viewHandlerAmounts.categorizedNodes.value?.Electricity?.Return || [])
    const generationNodes = computed(
      () => props.viewHandlerAmounts.categorizedNodes.value?.Electricity?.Generation || [],
    )

    const usageNodesCount = computed(() => usageNodes.value?.length || 0)
    const returnNodesCount = computed(() => returnNodes.value?.length || 0)
    const generationNodesCount = computed(() => generationNodes.value?.length || 0)

    const hasElectricityData = computed(() => {
      return !!props.viewHandlerAmounts.dataElectricity.value
    })

    const hasUsageData = computed(() => {
      return !!props.viewHandlerAmounts.dataElectricity.value?.Usage
    })

    const hasReturnData = computed(() => {
      return !!props.viewHandlerAmounts.dataElectricity.value?.Return
    })

    const hasGenerationData = computed(() => {
      return !!props.viewHandlerAmounts.dataElectricity.value?.Generation
    })

    const calculateSaldo = computed(() => {
      const usage = props.viewHandlerAmounts.sumSummary(props.viewHandlerAmounts.dataElectricity.value?.Usage) || 0
      const returnVal = props.viewHandlerAmounts.sumSummary(props.viewHandlerAmounts.dataElectricity.value?.Return) || 0
      return usage - returnVal
    })

    const calculateEigenGebruik = computed(() => {
      const generation =
        props.viewHandlerAmounts.sumSummary(props.viewHandlerAmounts.dataElectricity.value?.Generation) || 0
      const returnVal = props.viewHandlerAmounts.sumSummary(props.viewHandlerAmounts.dataElectricity.value?.Return) || 0
      return Math.max(0, generation - returnVal)
    })

    const combinedChartOptions = computed(() => {
      if (!props.viewHandlerAmounts.chart_options_usage_electricity?.value) return null

      const baseOptions = JSON.parse(JSON.stringify(props.viewHandlerAmounts.chart_options_usage_electricity.value))

      // Clear existing series
      baseOptions.series = []

      // Add plotOptions to prevent stacking and set column grouping
      baseOptions.plotOptions = {
        column: {
          stacking: undefined, // Remove stacking
          grouping: true, // Enable grouping of columns
          pointPadding: 0.05, // Add small padding between columns
          groupPadding: 0.2, // Add padding between groups
        },
      }

      // Add Usage series if available
      if (props.viewHandlerAmounts.chart_options_usage_electricity?.value?.series?.[0]) {
        baseOptions.series.push({
          ...props.viewHandlerAmounts.chart_options_usage_electricity.value.series[0],
          name: 'Levering',
          color: '#F2CE1B', // Yellow for usage
          type: 'column',
        })
      }

      // Add Return series only if data exists
      if (hasReturnData.value && props.viewHandlerAmounts.chart_options_return_electricity?.value?.series?.[0]) {
        baseOptions.series.push({
          ...props.viewHandlerAmounts.chart_options_return_electricity.value.series[0],
          name: 'Teruglevering',
          color: '#E57A44', // Orange for return
          type: 'column',
        })
      }

      // Add Generation series only if data exists
      if (
        hasGenerationData.value &&
        props.viewHandlerAmounts.chart_options_generation_electricity?.value?.series?.[0]
      ) {
        baseOptions.series.push({
          ...props.viewHandlerAmounts.chart_options_generation_electricity.value.series[0],
          name: 'Opwekking',
          color: '#925E78', // Purple for generation
          type: 'column',
        })
      }

      return baseOptions
    })

    // Function to adjust chart data for per dimension values
    function getAdjustedChartOptions(options) {
      if (
        !options ||
        !props.viewHandlerAmounts.showPerDimension?.value ||
        !props.viewHandlerAmounts.filterForm.data.value.dimension
      ) {
        return options
      }

      const dimension = props.viewHandlerAmounts.filterForm.data.value.dimension
      const newOptions = JSON.parse(JSON.stringify(options)) // Deep clone to avoid modifying original

      // Adjust series data
      if (newOptions.series) {
        newOptions.series = newOptions.series.map((series) => ({
          ...series,
          data: series.data.map((point) => {
            if (Array.isArray(point)) {
              // For array format [date, value]
              return [point[0], point[1] / dimension]
            } else if (typeof point === 'object' && point !== null) {
              // For object format with x,y coordinates
              return {
                ...point,
                y: point.y / dimension,
                value: point.value ? point.value / dimension : undefined,
              }
            }
            // For simple number values
            return point / dimension
          }),
        }))
      }

      // Update yAxis labels to show per dimension unit
      if (newOptions.yAxis) {
        newOptions.yAxis = {
          ...newOptions.yAxis,
          labels: {
            ...newOptions.yAxis.labels,
            formatter: function () {
              return useFilter.filter(this.value, displayUnit.value)
            },
          },
        }
      }

      // Update tooltip to show per dimension values
      if (newOptions.tooltip) {
        newOptions.tooltip = {
          ...newOptions.tooltip,
          formatter: function () {
            const context = this
            const value = context.y
            const date = context.point.name || context.x
            const seriesName = context.series.name

            // For scatter plots
            if (context.point.date) {
              return `${context.point.date}<br>${seriesName}: ${useFilter.filter(value, displayUnit.value)}`
            }

            // For regular charts
            if (typeof date === 'string') {
              return `${date}<br>${seriesName}: ${useFilter.filter(value, displayUnit.value)}`
            }

            // For timestamp dates
            const formattedDate = new Date(date).toLocaleDateString('nl-NL', {
              year: 'numeric',
              month: 'short',
            })
            return `${formattedDate}<br>${seriesName}: ${useFilter.filter(value, displayUnit.value)}`
          },
        }
      }

      // Update plotOptions for column charts
      if (newOptions.plotOptions?.column) {
        newOptions.plotOptions.column = {
          ...newOptions.plotOptions.column,
          dataLabels: {
            ...newOptions.plotOptions.column.dataLabels,
            formatter: function () {
              return useFilter.filter(this.y, displayUnit.value)
            },
          },
        }
      }

      return newOptions
    }

    return {
      useFilter,
      dutchDateParser,
      weekdayParser,
      dutchNumberParser,
      WeekdayProfileInfo,
      usageNodesCount,
      returnNodesCount,
      generationNodesCount,
      hasElectricityData,
      hasUsageData,
      hasReturnData,
      hasGenerationData,
      activeSection,
      usageNodes,
      displayUnit,
      getAdjustedChartOptions,
      calculateSaldo,
      calculateEigenGebruik,
      combinedChartOptions,
    }
  },
  components: {
    cardChart,
  },
}
</script>
