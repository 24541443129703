<!-- src/components/info/WeekdayProfileInfo.vue -->
<template>
  <div class="p-6 text-gray-500">
    <h2 class="text-lg font-medium mb-6 text-gray-600">Het Dagprofiel</h2>

    <p class="mb-6">
      Het dagprofiel toont het gemiddelde verbruikspatroon per uur voor elke dag van de week. Deze grafiek wordt als
      volgt opgebouwd:
    </p>

    <div class="mb-6">
      <h3 class="text-md font-medium mb-3 text-gray-600">Wat zie ik?</h3>
      <ul class="space-y-2 ml-4">
        <li class="flex items-start">
          <span class="h-1.5 w-1.5 rounded-full bg-gray-400 mt-2 mr-2 flex-shrink-0"></span>
          <span>Per weekdag (ma-zo) wordt voor elk uur (0-23) het gemiddelde verbruik berekend</span>
        </li>
        <li class="flex items-start">
          <span class="h-1.5 w-1.5 rounded-full bg-gray-400 mt-2 mr-2 flex-shrink-0"></span>
          <span>Elke lijn in de grafiek vertegenwoordigt een andere dag van de week</span>
        </li>
        <li class="flex items-start">
          <span class="h-1.5 w-1.5 rounded-full bg-gray-400 mt-2 mr-2 flex-shrink-0"></span>
          <span>De X-as toont de uren van de dag (0-23 uur)</span>
        </li>
        <li class="flex items-start">
          <span class="h-1.5 w-1.5 rounded-full bg-gray-400 mt-2 mr-2 flex-shrink-0"></span>
          <span>De Y-as toont het gemiddelde verbruik</span>
        </li>
      </ul>
    </div>

    <div class="mb-6">
      <h3 class="text-md font-medium mb-3 text-gray-600">Hoe wordt dit berekend?</h3>
      <ul class="space-y-2 ml-4">
        <li class="flex items-start">
          <span class="h-1.5 w-1.5 rounded-full bg-gray-400 mt-2 mr-2 flex-shrink-0"></span>
          <span>Voor elk uur van elke weekdag worden alle beschikbare metingen verzameld</span>
        </li>
        <li class="flex items-start">
          <span class="h-1.5 w-1.5 rounded-full bg-gray-400 mt-2 mr-2 flex-shrink-0"></span>
          <span>Deze metingen worden bij elkaar opgeteld en gedeeld door het aantal metingen</span>
        </li>
        <li class="flex items-start">
          <span class="h-1.5 w-1.5 rounded-full bg-gray-400 mt-2 mr-2 flex-shrink-0"></span>
          <span>Bijvoorbeeld: alle maandagen om 14:00 uur worden gemiddeld</span>
        </li>
        <li class="flex items-start">
          <span class="h-1.5 w-1.5 rounded-full bg-gray-400 mt-2 mr-2 flex-shrink-0"></span>
          <span>Dit zorgt voor een representatief beeld van het verbruik per weekdag</span>
        </li>
      </ul>
    </div>

    <div>
      <h3 class="text-md font-medium mb-3 text-gray-600">Bijzonderheden</h3>
      <p class="mb-4">Het aantal metingen per uur kan variëren door de zomer- en wintertijd overgangen:</p>

      <div class="mb-4">
        <h4 class="font-medium mb-2 text-gray-600">Zomertijd (maart):</h4>
        <ul class="space-y-2 ml-4">
          <li class="flex items-start">
            <span class="h-1.5 w-1.5 rounded-full bg-gray-400 mt-2 mr-2 flex-shrink-0"></span>
            <span>Bij de start van de zomertijd springt de klok van 2:00 naar 3:00 uur</span>
          </li>
          <li class="flex items-start">
            <span class="h-1.5 w-1.5 rounded-full bg-gray-400 mt-2 mr-2 flex-shrink-0"></span>
            <span>Het uur van 2:00-3:00 bestaat dan niet</span>
          </li>
          <li class="flex items-start">
            <span class="h-1.5 w-1.5 rounded-full bg-gray-400 mt-2 mr-2 flex-shrink-0"></span>
            <span>Voor dit uur zijn er die dag dus geen meetwaarden</span>
          </li>
          <li class="flex items-start">
            <span class="h-1.5 w-1.5 rounded-full bg-gray-400 mt-2 mr-2 flex-shrink-0"></span>
            <span>In het jaartotaal heeft dit uur één meting minder voor die specifieke weekdag</span>
          </li>
        </ul>
      </div>

      <div class="mb-4">
        <h4 class="font-medium mb-2 text-gray-600">Wintertijd (oktober):</h4>
        <ul class="space-y-2 ml-4">
          <li class="flex items-start">
            <span class="h-1.5 w-1.5 rounded-full bg-gray-400 mt-2 mr-2 flex-shrink-0"></span>
            <span>Bij de start van de wintertijd gaat de klok van 3:00 terug naar 2:00 uur</span>
          </li>
          <li class="flex items-start">
            <span class="h-1.5 w-1.5 rounded-full bg-gray-400 mt-2 mr-2 flex-shrink-0"></span>
            <span>Het uur van 2:00-3:00 komt dan twee keer voor</span>
          </li>
          <li class="flex items-start">
            <span class="h-1.5 w-1.5 rounded-full bg-gray-400 mt-2 mr-2 flex-shrink-0"></span>
            <span>Voor dit uur zijn er die dag dus twee meetwaarden</span>
          </li>
          <li class="flex items-start">
            <span class="h-1.5 w-1.5 rounded-full bg-gray-400 mt-2 mr-2 flex-shrink-0"></span>
            <span>In het jaartotaal heeft dit uur één meting extra voor die specifieke weekdag</span>
          </li>
        </ul>
      </div>

      <p class="mt-4">
        Deze verschillen worden automatisch verwerkt in het dagprofiel omdat we werken met gemiddelden: de totale som
        van alle meetwaarden per uur wordt gedeeld door het werkelijke aantal metingen voor dat uur.
      </p>
    </div>
  </div>
</template>
