import { ref } from 'vue'
import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
import formHandler from '@/use/formHandler'

export default () => {
  let records = ref([])
  let showAdd = ref(false)
  let showEdit = ref(false)
  const useApi = apiHandler()
  const loadHandler = loadingHandler()
  const addForm = formHandler()

  const scenarioFields = ref([
    {
      label: 'Description',
      key: 'description',
      type: 'string',
      searchable: true,
      required: true,
      maxLength: 100,
    },
  ])

  function listRecords() {
    loadHandler.setLoadingState('list_scenarios', true)

    useApi.request_api('get', 'v1', 'scenarios/costs').then((response) => {
      records.value = response.data
      loadHandler.setLoadingState('list_scenarios', false)
    })
  }

  function openAdd() {
    addForm.create({
      type: 'add',
      fields: scenarioFields.value,
      data: {},
    })
    showAdd.value = true
  }

  function add() {
    loadHandler.setLoadingState('add_scenario', true)

    const payload = addForm.data.value
    payload['type'] = 'costs'
    payload['configuration'] = {
      start_date: new Date().setMonth(0, 1),
      end_date: new Date().getTime(),
      tags: [],
      filter_logic: 'and',
    }
    useApi.request_api('post', 'v1', 'scenarios/', payload).then(() => {
      closeAdd()
      listRecords()
      loadHandler.setLoadingState('add_scenario', false)
    })

    // useApi.request('post', 'scenarios', 'add', payload).then(() => {
    //   closeAdd()
    //   listRecords()
    //   loadHandler.setLoadingState('add_scenario', false)
    // })
  }

  function closeAdd() {
    showAdd.value = false
    addForm.close()
  }

  function deleteScenario(objectId) {
    loadHandler.setLoadingState('delete_scenario', true)
    useApi.request_api('delete', 'v1', 'scenarios/' + objectId).then(() => {
      listRecords()
      loadHandler.setLoadingState('delete_scenario', false)
    })
  }

  const tagsFields = ref([
    {
      label: 'Description',
      key: 'description',
      type: 'string',
      searchable: true,
    },
  ])

  return {
    loadHandler,
    tagsFields,
    records,
    showEdit,
    showAdd,
    listRecords,
    openAdd,
    scenarioFields,
    addForm,
    add,
    deleteScenario,
    closeAdd,
  }
}
