<template>
  <div class="p-6">
    <div class="flex flex-row justify-between">
      <base-page-title :title="pageTitle" :suffix="pageSuffix" :icon="meta.titleIcon"></base-page-title>
      <div class="my-auto"></div>
    </div>

    <div v-show="!useviewHandlerAmounts.loadHandler.somethingLoading.value">
      <baseTabs
        :handler="tabs"
        @changed="onTabChange"
        v-if="
          !useviewHandlerAmounts.loadHandler.states.get_details &&
          Object.keys(useviewHandlerAmounts.filterForm.data.value).length > 0
        "
      >
        <template v-slot:panel-electricity>
          <div class="w-full">
            <collapseElectricity
              v-if="useviewHandlerAmounts.dataElectricity.value"
              :viewHandlerAmounts="useviewHandlerAmounts"
              :key="useviewHandlerAmounts.showPerDimension.value"
            />
            <NodeDetailsTable
              v-if="
                useviewHandlerAmounts.dataElectricity.value &&
                useviewHandlerAmounts.nodeDetails.value?.Electricity &&
                useviewHandlerAmounts.amountsData.value?.node_data
              "
              :nodeDetails="useviewHandlerAmounts.nodeDetails.value.Electricity"
              :measurementData="{
                ...useviewHandlerAmounts.amountsData.value.node_data,
                showPerDimension: useviewHandlerAmounts.showPerDimension.value,
                dimension: useviewHandlerAmounts.filterForm.data.value.dimension,
                dimension_unit: useviewHandlerAmounts.filterForm.data.value.dimension_unit,
              }"
              :interval="useviewHandlerAmounts.filterForm.data.value.interval"
              :unit="
                useviewHandlerAmounts.showPerDimension.value &&
                useviewHandlerAmounts.filterForm.data.value.dimension_unit
                  ? `kWh/${useviewHandlerAmounts.filterForm.data.value.dimension_unit}`
                  : 'kWh'
              "
            />
          </div>
        </template>

        <!-- Other panels remain the same -->
        <template v-slot:panel-gas>
          <div class="w-full">
            <collapseGas
              v-if="useviewHandlerAmounts.dataGas.value"
              :viewHandlerAmounts="useviewHandlerAmounts"
              :key="useviewHandlerAmounts.showPerDimension.value"
            />
            <MediaDetailsTable
              v-if="
                useviewHandlerAmounts.dataGas.value &&
                useviewHandlerAmounts.nodeDetails.value?.Gas &&
                useviewHandlerAmounts.amountsData.value?.node_data
              "
              :nodeDetails="useviewHandlerAmounts.nodeDetails.value.Gas"
              :measurementData="{
                ...useviewHandlerAmounts.amountsData.value.node_data,
                showPerDimension: useviewHandlerAmounts.showPerDimension.value,
                dimension: useviewHandlerAmounts.filterForm.data.value.dimension,
                dimension_unit: useviewHandlerAmounts.filterForm.data.value.dimension_unit,
              }"
              :interval="useviewHandlerAmounts.filterForm.data.value.interval"
              :unit="
                useviewHandlerAmounts.showPerDimension.value &&
                useviewHandlerAmounts.filterForm.data.value.dimension_unit
                  ? `m3/${useviewHandlerAmounts.filterForm.data.value.dimension_unit}`
                  : 'm3'
              "
            />
          </div>
        </template>

        <template v-slot:panel-heat>
          <div class="w-full">
            <collapseHeat
              v-if="useviewHandlerAmounts.dataHeat.value"
              :viewHandlerAmounts="useviewHandlerAmounts"
              :key="useviewHandlerAmounts.showPerDimension.value"
            />
            <MediaDetailsTable
              v-if="
                useviewHandlerAmounts.dataHeat.value &&
                useviewHandlerAmounts.nodeDetails.value?.Heat &&
                useviewHandlerAmounts.amountsData.value?.node_data
              "
              :nodeDetails="useviewHandlerAmounts.nodeDetails.value.Heat"
              :measurementData="{
                ...useviewHandlerAmounts.amountsData.value.node_data,
                showPerDimension: useviewHandlerAmounts.showPerDimension.value,
                dimension: useviewHandlerAmounts.filterForm.data.value.dimension,
                dimension_unit: useviewHandlerAmounts.filterForm.data.value.dimension_unit,
              }"
              :interval="useviewHandlerAmounts.filterForm.data.value.interval"
              :unit="
                useviewHandlerAmounts.showPerDimension.value &&
                useviewHandlerAmounts.filterForm.data.value.dimension_unit
                  ? `GJ/${useviewHandlerAmounts.filterForm.data.value.dimension_unit}`
                  : 'GJ'
              "
            />
          </div>
        </template>

        <template v-slot:panel-heat-cost>
          <div class="w-full">
            <collapseHeatCost
              v-if="useviewHandlerAmounts.dataHeatCost.value"
              :viewHandlerAmounts="useviewHandlerAmounts"
              :key="useviewHandlerAmounts.showPerDimension.value"
            />
            <MediaDetailsTable
              v-if="
                useviewHandlerAmounts.dataHeatCost.value &&
                useviewHandlerAmounts.nodeDetails.value?.HeatCost &&
                useviewHandlerAmounts.amountsData.value?.node_data
              "
              :nodeDetails="useviewHandlerAmounts.nodeDetails.value.HeatCost"
              :measurementData="{
                ...useviewHandlerAmounts.amountsData.value.node_data,
                showPerDimension: useviewHandlerAmounts.showPerDimension.value,
                dimension: useviewHandlerAmounts.filterForm.data.value.dimension,
                dimension_unit: useviewHandlerAmounts.filterForm.data.value.dimension_unit,
              }"
              :interval="useviewHandlerAmounts.filterForm.data.value.interval"
              :unit="
                useviewHandlerAmounts.showPerDimension.value &&
                useviewHandlerAmounts.filterForm.data.value.dimension_unit
                  ? `Eenheden/${useviewHandlerAmounts.filterForm.data.value.dimension_unit}`
                  : 'Eenheden'
              "
            />
          </div>
        </template>

        <template v-slot:panel-water>
          <div class="w-full">
            <collapseWater
              v-if="useviewHandlerAmounts.dataWater.value"
              :viewHandlerAmounts="useviewHandlerAmounts"
              :key="useviewHandlerAmounts.showPerDimension.value"
            />
            <MediaDetailsTable
              v-if="
                useviewHandlerAmounts.dataWater.value &&
                useviewHandlerAmounts.nodeDetails.value?.Water &&
                useviewHandlerAmounts.amountsData.value?.node_data
              "
              :nodeDetails="useviewHandlerAmounts.nodeDetails.value.Water"
              :measurementData="{
                ...useviewHandlerAmounts.amountsData.value.node_data,
                showPerDimension: useviewHandlerAmounts.showPerDimension.value,
                dimension: useviewHandlerAmounts.filterForm.data.value.dimension,
                dimension_unit: useviewHandlerAmounts.filterForm.data.value.dimension_unit,
              }"
              :interval="useviewHandlerAmounts.filterForm.data.value.interval"
              :unit="
                useviewHandlerAmounts.showPerDimension.value &&
                useviewHandlerAmounts.filterForm.data.value.dimension_unit
                  ? `m3/${useviewHandlerAmounts.filterForm.data.value.dimension_unit}`
                  : 'm3'
              "
            />
          </div>
        </template>

        <template v-slot:button>
          <div class="flex items-center h-10 gap-4">
            <div class="flex items-center h-full gap-1" v-if="useviewHandlerAmounts.filterForm.data.value.dimension">
              <field-toggle class="flex items-center mr-2" v-model="useviewHandlerAmounts.showPerDimension.value" />
              <span class="text-sm text-gray-500 flex items-center">
                Per {{ useviewHandlerAmounts.filterForm.data.value.dimension_unit }}
              </span>
            </div>
            <base-button
              variant="neutral"
              :icon="'SettingsIcon'"
              class="text-gray-500 flex items-center justify-center"
              @action="useviewHandlerAmounts.openFilterModal()"
            >
              <featherIcon class="w-4 text-gray-500" icon="SettingsIcon" />
            </base-button>
          </div>
        </template>
      </baseTabs>

      <div v-else class="text-gray-500 ml-2">
        <baseLoader loading />
      </div>
    </div>

    <!-- filter modal -->
    <baseModal
      v-if="useviewHandlerAmounts.showFilterModal.value"
      @close="useviewHandlerAmounts.closeFilterModal()"
      :title="`${$translate('Edit')}`"
      size="m"
    >
      <baseForm :handler="useviewHandlerAmounts.filterForm" />
      <template v-slot:buttons>
        <baseButton @action="useviewHandlerAmounts.applyFilter()" class="mr-2">{{ $translate('Edit') }}</baseButton>
      </template>
    </baseModal>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { computed, onMounted, onBeforeUnmount, watch } from 'vue'
import viewHandlerAmounts from '@/use/viewHandlerAmounts'
import filterHandler from '@/use/filterHandler'
import featherIcon from '@/components/extended/feather/featherIcon.vue'
import collapseElectricity from './collapseElectricity.vue'
import collapseGas from './collapseGas.vue'
import collapseWater from './collapseWater.vue'
import collapseHeat from './collapseHeat.vue'
import collapseHeatCost from './collapseHeatCost.vue'
import useTabs from '@/use/tabHandler'
import NodeDetailsTable from './NodeDetailsTable.vue'
import MediaDetailsTable from './MediaDetailsTable.vue'
import fieldToggle from '@/plugins/base/components/form/fieldToggle.vue'

export default {
  setup() {
    const route = useRoute()
    const useviewHandlerAmounts = viewHandlerAmounts()
    const useFilter = filterHandler()
    const meta = computed(() => route.meta)
    const tabs = useTabs()

    const pageTitle = computed(() => {
      return meta.value.title
    })

    const pageSuffix = computed(() => {
      if (!useviewHandlerAmounts.loadHandler.states.get_details) {
        return `${useviewHandlerAmounts.scenario.value?.description || ''}`
      }
      return ''
    })

    const hasNodesForMedium = (medium) => {
      const nodes = useviewHandlerAmounts.categorizedNodes.value[medium]
      return nodes && (nodes.Usage.length > 0 || nodes.Return.length > 0 || nodes.Generation.length > 0)
    }

    const tabOptions = computed(() => {
      return {
        tabs: [
          {
            id: 'electricity',
            label: 'Elektriciteit',
            active: !!useviewHandlerAmounts.dataElectricity.value && hasNodesForMedium('Electricity'),
          },
          {
            id: 'gas',
            label: 'Gas',
            active: !!useviewHandlerAmounts.dataGas.value && hasNodesForMedium('Gas'),
          },
          {
            id: 'heat',
            label: 'Warmte',
            active: !!useviewHandlerAmounts.dataHeat.value && hasNodesForMedium('Heat'),
          },
          {
            id: 'heat-cost',
            label: 'Warmtekosten',
            active: !!useviewHandlerAmounts.dataHeatCost.value && hasNodesForMedium('HeatCost'),
          },
          {
            id: 'water',
            label: 'Water',
            active: !!useviewHandlerAmounts.dataWater.value && hasNodesForMedium('Water'),
          },
        ],
      }
    })

    // Watch for changes in tabOptions and update tabs
    watch(
      () => tabOptions.value,
      (newOptions) => {
        if (newOptions) {
          tabs.create(newOptions, 'electricity')
        }
      },
      { deep: true },
    )

    function onTabChange() {
      console.log('change')
    }

    onMounted(() => {
      useviewHandlerAmounts.getDetails({
        object_id: route.params.objectId,
      })

      // Initial tab creation
      tabs.create(tabOptions.value, 'electricity')
    })

    onBeforeUnmount(() => {
      useviewHandlerAmounts.resetData()
    })

    return {
      meta,
      useviewHandlerAmounts,
      useFilter,
      pageTitle,
      pageSuffix,
      tabs,
      onTabChange,
      tabOptions,
    }
  },
  components: {
    featherIcon,
    collapseElectricity,
    collapseGas,
    collapseWater,
    collapseHeat,
    collapseHeatCost,
    NodeDetailsTable,
    MediaDetailsTable,
    fieldToggle,
  },
}
</script>
