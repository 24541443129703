<template>
  <div class="p-6">
    <base-page-title :title="meta.title" :icon="meta.titleIcon"></base-page-title>
    <baseTable
      :fields="useViewLocations.locationFields.value"
      :records="useViewLocations.records.value"
      :records-per-page="25"
      :add-button="useContentGuard.canSee('object:write')"
      :edit-button="false"
      :delete-button="useContentGuard.canSee('object:write')"
      :clickAble="true"
      :clickCallback="tableAction"
      :loading="useViewLocations.loadHandler.somethingLoading.value"
      :hover-effect="true"
      :export-button="true"
      @add="useViewLocations.openAdd()"
      @delete="useViewLocations.deleteLocation"
      :sortable="true"
      @export="onExport"
    />

    <!-- leadIcon="MapPinIcon" -->

    <!-- add modal -->
    <base-modal
      v-if="useViewLocations.showAdd.value"
      @close="useViewLocations.closeAdd()"
      :title="`${$translate('Add')}`"
    >
      <baseForm :handler="useViewLocations.addForm" />
      <template v-slot:buttons>
        <baseButton
          @action="useViewLocations.add()"
          class="mr-2"
          :loading="useViewLocations.loadHandler.states.add_location"
          >{{ $translate('Save') }}</baseButton
        >
      </template>
    </base-modal>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { computed, onMounted } from 'vue'
import viewHandlerLocations from '@/use/viewHandlerLocations'
import router from '@/router'
import contentGuardHandler from '@/use/contentGuardHandler'
import exportExcelHandler from '@/use/exportExcelHandler'

export default {
  setup() {
    const route = useRoute()
    const useViewLocations = viewHandlerLocations()
    const useContentGuard = contentGuardHandler()
    const useExportExcel = exportExcelHandler()

    function onExport(data) {
      useExportExcel.exportExcel('SimpleWEG locations', 'locations', data.records, data.fields)
    }

    function tableAction(payload) {
      router.push({
        name: 'location_details',
        params: { objectId: payload.object_id },
      })
    }

    onMounted(() => {
      useViewLocations.listRecords()
    })

    const meta = computed(() => route.meta)
    return {
      meta,
      useViewLocations,
      tableAction,
      useContentGuard,
      onExport,
    }
  },
}
</script>
