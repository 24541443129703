<template>
  <span>
    <label class="relative inline-flex items-center cursor-pointer">
      <input
        :value="true"
        :disabled="disabled"
        type="checkbox"
        class="sr-only peer"
        :checked="modelValue"
        @input="$emit('update:modelValue', $event.target.checked)"
      />
      <div
        class="my-auto w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-sky-700"
      ></div>
    </label>
  </span>
</template>

<script>
export default {
  name: 'ToggleComponent',
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
}
</script>
