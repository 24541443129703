import { ref, computed } from 'vue'
import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
import formHandler from '@/use/formHandler'
import selectValues from '@/use/selects/values'
import chartOptionshandler from '@/use/chartOptionsHandler'
import timeHandler from './timeHandler'
import filterHandler from '@/use/filterHandler'

let amountsData = ref({})
let storedWeatherData = ref({})
const filterForm = formHandler()
const useTime = timeHandler()
const showPerDimension = ref(false)

// Add new ref for detailed node information
let nodeDetails = ref({
  Electricity: { Usage: [], Return: [], Generation: [] },
  Gas: { Usage: [], Return: [], Generation: [] },
  Water: { Usage: [], Return: [], Generation: [] },
  Heat: { Usage: [], Return: [], Generation: [] },
})

const filterFields = ref([
  {
    label: 'Description',
    key: 'description',
    type: 'string',
    required: true,
    maxLength: 100,
  },
  {
    label: 'Start date',
    key: 'start_date',
    type: 'date',
  },
  {
    label: 'End date',
    key: 'end_date',
    type: 'date',
  },
  {
    label: 'Interval',
    key: 'interval',
    type: 'select',
    options: selectValues.intervalAmounts,
    translate_value: true,
  },
  {
    label: 'Dimension',
    key: 'dimension',
    type: 'number',
    table: false,
  },
  {
    label: 'Dimension Unit',
    key: 'dimension_unit',
    type: 'select',
    options: selectValues.dimensionUnits,
    table: false,
  },
  {
    label: 'Tags',
    key: 'tags',
    type: 'tags',
    component: 'pillLabels',
    mode: 'Search',
  },
  {
    label: 'Label filtertype',
    key: 'filter_logic',
    type: 'select',
    options: selectValues.tagFilterLogic,
    dependent_show_field_key: 'tags',
    dependent_show_field_type: 'labels',
    translate_value: true,
  },
])

filterForm.create({
  type: 'edit',
  fields: filterFields.value,
  data: {},
})

export default () => {
  let filter = ref({})
  let scenario = ref({})
  const useApi = apiHandler()
  const useFilter = filterHandler()
  const loadHandler = loadingHandler()
  const showFilterModal = ref(false)
  const dateStartYear = new Date(new Date().getFullYear(), 0, 1, 0, 0, 0, 0).getTime()
  const dateEndYear = new Date(new Date().getFullYear(), 11, 31, 0, 0, 0, 0).getTime()
  const useChartOptions = chartOptionshandler()
  const splitDate = ref(null)

  const scenarioDescription = computed(() => {
    return scenario.value?.description || ''
  })

  function sumSummary(data) {
    if (data) {
      let sum = 0
      data.forEach((element) => {
        sum += element[1]
      })
      return showPerDimension.value && filterForm.data.value.dimension ? sum / filterForm.data.value.dimension : sum
    }
    return 0
  }

  function storeWeatherData(data) {
    storedWeatherData.value = parseKnmi(data)
  }

  let categorizedNodes = ref({
    Electricity: { Usage: [], Return: [], Generation: [] },
    Gas: { Usage: [], Return: [], Generation: [] },
    Water: { Usage: [], Return: [], Generation: [] },
    Heat: { Usage: [], Return: [], Generation: [] },
  })

  function closeFilterModal() {
    showFilterModal.value = false
    applyFilter()
  }

  function openFilterModal() {
    showFilterModal.value = true
  }

  function applyFilter() {
    resetOnlyData()
    updateScenario()
    fetchNodesByTags()
    showFilterModal.value = false
  }

  function linearRegression(data) {
    const n = data.length
    let sumX = 0
    let sumY = 0
    let sumXY = 0
    let sumXX = 0

    for (let i = 0; i < n; i++) {
      sumX += data[i][1]
      sumY += data[i][2]
      sumXY += data[i][1] * data[i][2]
      sumXX += data[i][1] * data[i][1]
    }

    const slope = (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX)
    const intercept = (sumY - slope * sumX) / n

    return { slope, intercept }
  }

  function fetchNodesByTags() {
    const testpayload = {
      tags: useApi.arrayToCommaSeparatedString(filterForm.data.value.tags.map((tag) => tag.name)),
      tag_relation: filterForm.data.value.filter_logic,
      fields: 'type,medium,object_id,description,provider_identifier',
    }

    const queryString = useApi.toQueryString(testpayload)

    useApi.request_api('get', 'v1', 'nodes?' + queryString).then((response) => {
      const nodes = response.data
      categorizeNodesByMedium(nodes)

      if (filterForm.data.value.interval === 'day') {
        fetchWeatherData().then((weatherData) => {
          storeWeatherData(weatherData)
        })
      }
    })
  }

  function parseKnmi(data) {
    const parsedData = {}
    data.forEach((record) => {
      const dateString = record.interval_start
      const datetimeObject = new Date(dateString)
      parsedData[datetimeObject.getTime()] = {
        TG: record.tg,
      }
    })
    return parsedData
  }

  function joinMeasurementsWithWeather(measurements, weatherData) {
    const returnMeasurements = []
    measurements.forEach((measurement) => {
      const intervalStart = new Date(measurement[0]).getTime()
      const weatherRecord = weatherData[intervalStart]

      if (weatherRecord && 'TG' in weatherRecord) {
        returnMeasurements.push([
          useFilter.dateFromEpochDay(intervalStart),
          weatherRecord.TG,
          Math.round((measurement[1] + Number.EPSILON) * 100) / 100,
        ])
      }
    })

    return returnMeasurements
  }

  function categorizeNodesByMedium(nodes) {
    // Reset data structures
    categorizedNodes.value = {
      Electricity: { Usage: [], Return: [], Generation: [] },
      Gas: { Usage: [], Return: [], Generation: [] },
      Water: { Usage: [], Return: [], Generation: [] },
      Heat: { Usage: [], Return: [], Generation: [] },
      HeatCost: { Usage: [], Return: [], Generation: [] },
    }

    nodeDetails.value = {
      Electricity: { Usage: [], Return: [], Generation: [] },
      Gas: { Usage: [], Return: [], Generation: [] },
      Water: { Usage: [], Return: [], Generation: [] },
      Heat: { Usage: [], Return: [], Generation: [] },
      HeatCost: { Usage: [], Return: [], Generation: [] },
    }

    // Categorize nodes first
    nodes.forEach((node) => {
      if (node.type) {
        const count_usage = node.type.count_usage
        const count_return = node.type.count_return
        const count_generation = node.type.count_generation
        const nodeInfo = {
          id: node.object_id,
          description: node.description || '',
          type: node.type,
          medium: node.medium,
          provider_identifier: node.provider_identifier,
        }

        if (node.medium in categorizedNodes.value) {
          if (count_usage === true) {
            categorizedNodes.value[node.medium].Usage.push(node.object_id)
            nodeDetails.value[node.medium].Usage.push(nodeInfo)
          }
          if (count_return === true) {
            categorizedNodes.value[node.medium].Return.push(node.object_id)
            nodeDetails.value[node.medium].Return.push(nodeInfo)
          }
          if (count_generation === true) {
            categorizedNodes.value[node.medium].Generation.push(node.object_id)
            nodeDetails.value[node.medium].Generation.push(nodeInfo)
          }
        }
      }
    })

    // Initialize amountsData
    amountsData.value = {
      node_data: {},
    }

    // Collect all API calls
    const apiCalls = []
    Object.keys(categorizedNodes.value).forEach((medium) => {
      amountsData.value[medium] = {}
      Object.keys(categorizedNodes.value[medium]).forEach((type) => {
        if (categorizedNodes.value[medium][type].length > 0) {
          const payload = {
            start_date: filterForm.data.value.start_date,
            end_date: filterForm.data.value.end_date,
            interval: filterForm.data.value.interval,
            ids: categorizedNodes.value[medium][type],
            aggregation_mode: 'custom',
            include_node_data: true,
          }

          const queryString = useApi.toQueryString(payload)
          loadHandler.setLoadingState(`get_summary_${medium.toLowerCase()}_${type.toLowerCase()}`, true)

          apiCalls.push(
            useApi
              .request_api('get', 'v1/nodes', 'data/summary?' + queryString)
              .then((response) => {
                const data = response.data

                // Store the main data
                if (type === 'Usage') {
                  amountsData.value[medium][type] = data.usage
                } else {
                  amountsData.value[medium][type] = data.production
                }

                // Merge node_data
                if (data.node_data) {
                  amountsData.value.node_data = {
                    ...amountsData.value.node_data,
                    ...data.node_data,
                  }
                }

                loadHandler.setLoadingState(`get_summary_${medium.toLowerCase()}_${type.toLowerCase()}`, false)
              })
              .catch(() => {
                loadHandler.setLoadingState(`get_summary_${medium.toLowerCase()}_${type.toLowerCase()}`, false)
              }),
          )
        }
      })
    })

    // Wait for all API calls to complete
    Promise.all(apiCalls)
      .then(() => {
        console.log('All API calls completed')
      })
      .catch((error) => {
        console.error('Error in API calls:', error)
      })
  }

  async function fetchWeatherData() {
    const payload = {
      start_date: filterForm.data.value.start_date,
      end_date: filterForm.data.value.end_date,
      station_id: '260',
      fields: 'tg,interval_start',
    }
    const queryString = useApi.toQueryString(payload)

    const response = await useApi.request_api('get', 'v1', 'weather?' + queryString)
    return response.data
  }

  const dataGas = computed(() => amountsData.value['Gas'])
  const dataWater = computed(() => amountsData.value['Water'])
  const dataElectricity = computed(() => amountsData.value['Electricity'])
  const dataHeat = computed(() => {
    return amountsData.value['Heat']
  })

  const dataHeatCost = computed(() => {
    return amountsData.value['HeatCost']
  })

  const chart_options_usage_heat_cost = computed(() => {
    if (dataHeatCost.value && dataHeatCost.value.Usage) {
      const parsedData = parseTimestamps(dataHeatCost.value.Usage, filterForm.data.value.interval)
      return useChartOptions.getOptions('HeatCost', parsedData, 'Usage', filterForm.data.value.interval)
    }
    return useChartOptions.getOptions('HeatCost', {})
  })

  const chart_options_usage_heat = computed(() => {
    if (dataHeat.value && dataHeat.value.Usage) {
      const parsedData = parseTimestamps(dataHeat.value.Usage, filterForm.data.value.interval)
      return useChartOptions.getOptions('Heat', parsedData, 'Usage', filterForm.data.value.interval)
    }
    return useChartOptions.getOptions('Heat', {})
  })

  const chart_options_usage_gas = computed(() => {
    if (dataGas.value) {
      const parsedData = parseTimestamps(dataGas.value.Usage, filterForm.data.value.interval)
      return useChartOptions.getOptions('Gas', parsedData, 'Usage', filterForm.data.value.interval)
    }
    return useChartOptions.getOptions('Gas', {})
  })

  const chart_options_usage_water = computed(() => {
    if (dataWater.value) {
      const parsedData = parseTimestamps(dataWater.value.Usage, filterForm.data.value.interval)
      return useChartOptions.getOptions('Water', parsedData, 'Usage', filterForm.data.value.interval)
    }
    return useChartOptions.getOptions('Water', {})
  })

  const chart_options_usage_electricity = computed(() => {
    if (dataElectricity.value) {
      const parsedData = parseTimestamps(dataElectricity.value.Usage, filterForm.data.value.interval)
      return useChartOptions.getOptions('Electricity', parsedData, 'Usage', filterForm.data.value.interval)
    }
    return useChartOptions.getOptions('Electricity', {})
  })

  const chart_options_generation_electricity = computed(() => {
    if (dataElectricity.value) {
      const parsedData = parseTimestamps(dataElectricity.value.Generation, filterForm.data.value.interval)
      return useChartOptions.getOptions('Electricity', parsedData, 'Generation', filterForm.data.value.interval)
    }
    return useChartOptions.getOptions('Electricity', {})
  })

  const chart_options_return_electricity = computed(() => {
    if (dataElectricity.value) {
      const parsedData = parseTimestamps(dataElectricity.value.Return, filterForm.data.value.interval)
      return useChartOptions.getOptions('Electricity', parsedData, 'Return', filterForm.data.value.interval)
    }
    return useChartOptions.getOptions('Electricity', {})
  })

  const chart_options_usage_electricity_weekday = computed(() => {
    if (dataElectricity.value) {
      const parsedData = parseTimestamps(dataElectricity.value.Usage, 'hour')
      return useChartOptions.getWeekdayComparisonOptions(parsedData, 'Electricity')
    }
    return useChartOptions.getWeekdayComparisonOptions([], 'Electricity')
  })

  const chart_options_generation_electricity_weekday = computed(() => {
    if (dataElectricity.value) {
      const parsedData = parseTimestamps(dataElectricity.value.Generation, 'hour')
      return useChartOptions.getWeekdayComparisonOptions(parsedData, 'Electricity')
    }
    return useChartOptions.getWeekdayComparisonOptions([], 'Electricity')
  })

  const chart_options_return_electricity_weekday = computed(() => {
    if (dataElectricity.value) {
      const parsedData = parseTimestamps(dataElectricity.value.Return, 'hour')
      return useChartOptions.getWeekdayComparisonOptions(parsedData, 'Electricity')
    }
    return useChartOptions.getWeekdayComparisonOptions([], 'Electricity')
  })

  const chart_options_usage_gas_weekday = computed(() => {
    if (dataGas.value) {
      const parsedData = parseTimestamps(dataGas.value.Usage, 'hour')
      return useChartOptions.getWeekdayComparisonOptions(parsedData, 'Gas')
    }
    return useChartOptions.getWeekdayComparisonOptions([], 'Gas')
  })

  const chart_options_usage_water_weekday = computed(() => {
    if (dataWater.value) {
      const parsedData = parseTimestamps(dataWater.value.Usage, 'hour')
      return useChartOptions.getWeekdayComparisonOptions(parsedData, 'Water')
    }
    return useChartOptions.getWeekdayComparisonOptions([], 'Water')
  })

  const chart_options_usage_heat_weekday = computed(() => {
    if (dataHeat.value) {
      const parsedData = parseTimestamps(dataHeat.value.Usage, 'hour')
      return useChartOptions.getWeekdayComparisonOptions(parsedData, 'Heat')
    }
    return useChartOptions.getWeekdayComparisonOptions([], 'Heat')
  })

  const chart_options_usage_heat_cost_weekday = computed(() => {
    if (dataHeatCost.value) {
      const parsedData = parseTimestamps(dataHeatCost.value.Usage, 'hour')
      return useChartOptions.getWeekdayComparisonOptions(parsedData, 'HeatCost')
    }
    return useChartOptions.getWeekdayComparisonOptions([], 'HeatCost')
  })

  function parseTimestamps(data, interval) {
    const formattedUsage = data.map(([datetime, value]) => {
      if (interval === 'hour') {
        return [datetime, value]
      }
      const formattedDate = useTime.labelSelector(interval, new Date(datetime))
      return [formattedDate, value]
    })

    return formattedUsage
  }

  function calculateRegressionLine(data) {
    if (data.length === 0) return []

    const { slope, intercept } = linearRegression(data)

    const minTemp = Math.min(...data.map((point) => point[1]))
    const maxTemp = Math.max(...data.map((point) => point[1]))

    return [
      [minTemp, slope * minTemp + intercept],
      [maxTemp, slope * maxTemp + intercept],
    ]
  }

  function enrichWithWeatherData(measurements, weatherData, splitDate = null) {
    if (!measurements || !weatherData) return { data: [], regressionLine: [] }
    const result = joinMeasurementsWithWeather(measurements, weatherData)

    if (splitDate) {
      const dateBefore = result.filter((point) => new Date(point[0]) < splitDate)
      const dateAfter = result.filter((point) => new Date(point[0]) >= splitDate)

      const regressionLineBefore = calculateRegressionLine(dateBefore)
      const regressionLineAfter = calculateRegressionLine(dateAfter)

      return {
        data: { before: dateBefore, after: dateAfter },
        regressionLine: { before: regressionLineBefore, after: regressionLineAfter },
      }
    } else {
      const regressionLine = calculateRegressionLine(result)
      return { data: result, regressionLine }
    }
  }

  const chart_options_scatter_electricity = computed(() => {
    if (dataElectricity.value && storedWeatherData.value) {
      const { data, regressionLine } = enrichWithWeatherData(
        dataElectricity.value.Usage,
        storedWeatherData.value,
        splitDate.value,
      )
      return useChartOptions.getScatterOptions('Electricity', data, regressionLine, splitDate.value)
    }
    return useChartOptions.getScatterOptions('Electricity', [], {})
  })

  const chart_options_scatter_gas = computed(() => {
    if (dataGas.value && storedWeatherData.value) {
      const { data, regressionLine } = enrichWithWeatherData(
        dataGas.value.Usage,
        storedWeatherData.value,
        splitDate.value,
      )
      return useChartOptions.getScatterOptions('Gas', data, regressionLine, splitDate.value)
    }
    return useChartOptions.getScatterOptions('Gas', [], {})
  })

  const chart_options_scatter_water = computed(() => {
    if (dataWater.value && storedWeatherData.value) {
      const { data, regressionLine } = enrichWithWeatherData(
        dataWater.value.Usage,
        storedWeatherData.value,
        splitDate.value,
      )
      return useChartOptions.getScatterOptions('Water', data, regressionLine, splitDate.value)
    }
    return useChartOptions.getScatterOptions('Water', [], {})
  })

  const chart_options_scatter_heat = computed(() => {
    if (dataHeat.value && storedWeatherData.value) {
      const { data, regressionLine } = enrichWithWeatherData(
        dataHeat.value.Usage,
        storedWeatherData.value,
        splitDate.value,
      )
      return useChartOptions.getScatterOptions('Heat', data, regressionLine, splitDate.value)
    }
    return useChartOptions.getScatterOptions('Heat', [], {})
  })

  const chart_options_scatter_heat_cost = computed(() => {
    if (dataHeatCost.value && storedWeatherData.value) {
      const { data, regressionLine } = enrichWithWeatherData(
        dataHeatCost.value.Usage,
        storedWeatherData.value,
        splitDate.value,
      )
      return useChartOptions.getScatterOptions('HeatCost', data, regressionLine, splitDate.value)
    }
    return useChartOptions.getScatterOptions('HeatCost', [], {})
  })

  function getDetails(payload) {
    loadHandler.setLoadingState('get_details', true)
    useApi.request_api('get', 'v1', 'scenarios/' + payload.object_id).then((response) => {
      const data = response.data
      scenario.value = data

      const settings = data.configuration

      if (Object.keys(settings).length > 0) {
        filterForm.data.value.description = data.description
        filterForm.data.value.start_date = settings.start_date
        filterForm.data.value.end_date = settings.end_date
        filterForm.data.value.tags = settings.tags
        filterForm.data.value.interval = settings.interval
        filterForm.data.value.filter_logic = settings.filter_logic
        filterForm.data.value.dimension = settings.dimension
        filterForm.data.value.dimension_unit = settings.dimension_unit
      } else {
        const defaultFilter = {
          start_date: dateStartYear,
          end_date: dateEndYear,
          filter_logic: 'subset',
          interval: 'year',
          tags: [],
        }
        filterForm.data.value.start_date = defaultFilter.start_date
        filterForm.data.value.end_date = defaultFilter.end_date
        filterForm.data.value.tags = defaultFilter.tags
        filterForm.data.value.interval = defaultFilter.interval
        filterForm.data.value.filter_logic = defaultFilter.filter_logic
      }

      fetchNodesByTags()
      loadHandler.setLoadingState('get_details', false)
    })
  }

  function updateScenario() {
    console.log('update')
    scenario.value.description = filterForm.data.value.description
    const scenario_id = scenario.value.object_id
    const payload = {
      description: filterForm.data.value.description,
      configuration: {
        start_date: filterForm.data.value.start_date,
        end_date: filterForm.data.value.end_date,
        tags: filterForm.data.value.tags,
        interval: filterForm.data.value.interval,
        filter_logic: filterForm.data.value.filter_logic,
        dimension: filterForm.data.value.dimension,
        dimension_unit: filterForm.data.value.dimension_unit,
      },
    }
    loadHandler.setLoadingState('edit_scenario', false)

    useApi.request_api('patch', 'v1', 'scenarios/' + scenario_id, payload).then(() => {
      loadHandler.setLoadingState('edit_scenario', false)
    })
  }

  function resetData() {
    amountsData.value = {}
    filterForm.data.value = {}
  }

  function resetOnlyData() {
    amountsData.value = {}
  }

  return {
    loadHandler,
    amountsData,
    filter,
    filterForm,
    showFilterModal,
    closeFilterModal,
    openFilterModal,
    applyFilter,
    dataElectricity,
    dataGas,
    dataWater,
    dataHeat,
    dataHeatCost,
    chart_options_usage_electricity,
    chart_options_generation_electricity,
    chart_options_return_electricity,
    chart_options_usage_gas,
    chart_options_usage_water,
    chart_options_usage_heat,
    chart_options_usage_heat_cost,
    getDetails,
    updateScenario,
    scenario,
    resetData,
    fetchWeatherData,
    chart_options_scatter_electricity,
    chart_options_scatter_gas,
    chart_options_scatter_water,
    chart_options_scatter_heat,
    chart_options_scatter_heat_cost,
    storedWeatherData,
    chart_options_usage_electricity_weekday,
    chart_options_generation_electricity_weekday,
    chart_options_return_electricity_weekday,
    chart_options_usage_gas_weekday,
    chart_options_usage_water_weekday,
    chart_options_usage_heat_weekday,
    chart_options_usage_heat_cost_weekday,
    scenarioDescription,
    categorizedNodes,
    nodeDetails,
    showPerDimension,
    sumSummary,
  }
}
