import { ref } from 'vue'
import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
import formHandler from '@/use/formHandler'
import selectValues from '@/use/selects/values'

export default () => {
  let records = ref([])
  let showAdd = ref(false)
  const useApi = apiHandler()
  const loadHandler = loadingHandler()
  const addForm = formHandler()

  const currentYear = new Date().getFullYear()
  const startOfCurrentYear = new Date(currentYear, 0, 1).getTime()

  function listRecords() {
    loadHandler.setLoadingState('list_contracts', true)
    useApi.request_api('get', 'v1', 'contracts/').then((response) => {
      const data = response.data
      records.value = data
      records.value.forEach((record) => {
        record.nodes_count = record.nodes.length
      })
      loadHandler.setLoadingState('list_contracts', false)
    })
  }

  function openAdd() {
    addForm.create({
      type: 'edit',
      fields: contractFields.value,
      data: {
        type: 'Electricity',
        start_date: startOfCurrentYear,
        end_date: startOfCurrentYear,
        off_peak_hours: '23:00 - 07:00',
      },
    })
    showAdd.value = true
  }

  function closeAdd() {
    showAdd.value = false
    addForm.close()
  }

  function add() {
    loadHandler.setLoadingState('add_contract', true)

    const payload = addForm.getData()

    useApi.request_api('post', 'v1', 'contracts/', payload).then(() => {
      closeAdd()
      listRecords()
      loadHandler.setLoadingState('add_contract', false)
    })
  }

  function deleteContract(objectId) {
    loadHandler.setLoadingState('delete_contract', true)
    useApi.request_api('delete', 'v1', 'contracts/' + objectId).then(() => {
      listRecords()
      loadHandler.setLoadingState('delete_contract', false)
    })
  }

  const contractFields = ref([
    {
      label: 'Code',
      key: 'code',
      type: 'string',
      searchable: true,
      required: true,
      maxLength: 100,
    },
    {
      label: 'Type',
      key: 'type',
      type: 'select',
      component: 'pillMedium',
      options: selectValues.mediumContracts,
      searchable: true,
      translate_value: true,
    },
    {
      label: 'Provider',
      key: 'provider',
      type: 'string',
      searchable: true,
      required: true,
      maxLength: 100,
    },
    {
      label: 'Start date',
      key: 'start_date',
      type: 'date',
      filter: 'dateFromEpochDay',
    },
    {
      label: 'End date',
      key: 'end_date',
      type: 'date',
      filter: 'dateFromEpochDay',
    },
    {
      label: 'Peak rate',
      key: 'electricity_peak_rate',
      type: 'number',
      table: false,
      dependent_show_values: ['Electricity'],
      dependent_show_field_key: 'type',
      dependent_show_field_type: 'select',
      required: true,
    },
    {
      label: 'Off-peak rate',
      key: 'electricity_offpeak_rate',
      type: 'number',
      table: false,
      dependent_show_values: ['Electricity'],
      dependent_show_field_key: 'type',
      dependent_show_field_type: 'select',
      required: true,
    },
    {
      label: 'Peak return rate',
      key: 'electricity_peak_return_rate',
      type: 'number',
      table: false,
      dependent_show_values: ['Electricity'],
      dependent_show_field_key: 'type',
      dependent_show_field_type: 'select',
      required: true,
    },
    {
      label: 'Off-peak return rate',
      key: 'electricity_offpeak_return_rate',
      type: 'number',
      table: false,
      dependent_show_values: ['Electricity'],
      dependent_show_field_key: 'type',
      dependent_show_field_type: 'select',
      required: true,
    },
    {
      label: 'Off-peak hours',
      key: 'off_peak_hours',
      type: 'select',
      options: selectValues.offPeakHours,
      table: false,
      dependent_show_values: ['Electricity'],
      dependent_show_field_key: 'type',
      dependent_show_field_type: 'select',
    },
    {
      label: 'Gas rate',
      key: 'gas_rate',
      type: 'number',
      table: false,
      dependent_show_values: ['Gas'],
      dependent_show_field_key: 'type',
      dependent_show_field_type: 'select',
      required: true,
    },
    {
      label: 'Water rate',
      key: 'water_rate',
      type: 'number',
      table: false,
      dependent_show_values: ['Water'],
      dependent_show_field_key: 'type',
      dependent_show_field_type: 'select',
      required: true,
    },
    {
      label: 'Heat rate',
      key: 'heat_rate',
      type: 'number',
      table: false,
      dependent_show_values: ['Heat'],
      dependent_show_field_key: 'type',
      dependent_show_field_type: 'select',
      required: true,
    },
    {
      label: 'Heat cost rate',
      key: 'heatcost_rate',
      type: 'number',
      table: false,
      dependent_show_values: ['HeatCost'],
      dependent_show_field_key: 'type',
      dependent_show_field_type: 'select',
      required: true,
    },
    {
      label: 'Nodes',
      key: 'nodes_count',
      type: 'number',
      edit: false,
      dependent_show_values: ['Water'],
      dependent_show_field_key: 'type',
      dependent_show_field_type: 'select',
    },
  ])

  return {
    loadHandler,
    contractFields,
    records,
    add,
    showAdd,
    listRecords,
    openAdd,
    deleteContract,
    addForm,
    closeAdd,
  }
}
