import { ref } from 'vue'
import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
import formHandler from '@/use/formHandler'
import selectValues from '@/use/selects/values'

export default () => {
  let records = ref([])
  let showAdd = ref(false)
  const useApi = apiHandler()
  const loadHandler = loadingHandler()
  const addForm = formHandler()

  function listRecords() {
    loadHandler.setLoadingState('list_locations', true)
    useApi.request_api('get', 'v1', 'locations/').then((response) => {
      const data = response.data
      records.value = data
      records.value.forEach((record) => {
        record.nodes_count = record.nodes.length
      })
      loadHandler.setLoadingState('list_locations', false)
    })
  }

  function openAdd() {
    addForm.create({
      type: 'edit',
      fields: locationFields.value,
      data: {
        dimension_unit: 'm2',
      },
    })
    showAdd.value = true
  }

  function closeAdd() {
    showAdd.value = false
    addForm.close()
  }

  function add() {
    loadHandler.setLoadingState('add_location', true)
    const payload = addForm.getData()

    useApi.request_api('post', 'v1', 'locations/', payload).then(() => {
      closeAdd()
      listRecords()
      loadHandler.setLoadingState('add_location', false)
    })
  }

  function deleteLocation(objectId) {
    loadHandler.setLoadingState('delete_location', true)
    useApi.request_api('delete', 'v1', `locations/${objectId}`).then(() => {
      listRecords()
      loadHandler.setLoadingState('delete_location', false)
    })
  }

  const locationFields = ref([
    {
      label: 'Name',
      key: 'name',
      type: 'string',
      searchable: true,
      required: true,
      maxLength: 100,
    },
    {
      label: 'Dimension',
      key: 'dimension',
      type: 'number',
      table: false,
    },
    {
      label: 'Dimension Unit',
      key: 'dimension_unit',
      type: 'select',
      options: selectValues.dimensionUnits,
      table: false,
    },
    {
      label: 'Nodes',
      key: 'nodes_count',
      type: 'number',
      edit: false,
    },
  ])

  return {
    loadHandler,
    locationFields,
    records,
    showAdd,
    listRecords,
    openAdd,
    deleteLocation,
    addForm,
    closeAdd,
    add,
  }
}
